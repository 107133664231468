<template>
  <div>
    <div class="modal-header">
      <div class="modal-title">个人中心</div>
    </div>
    <div class="Info">
      <a-row>
        <a-col :span="6"> 工号 </a-col>
        <a-col :span="18">
          {{ jobNumber }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> 姓名 </a-col>
        <a-col :span="18">
          {{ realName }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="6"> 公司 </a-col>
        <a-col :span="18">
          {{ tenantName }}
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="6">审核签名</a-col>
        <a-col :span="18">
          <img
              :src="Imgurl"
              v-if="IsHaveImg"
              style="width: 200px;"
          />
          <a-button @click="SetImg" type="primary">设置签名</a-button>
          <a-button
            v-if="IsHaveImg"
            @click="CleanImg"
            style="background-color: #dd7633; border: #dd7633; color: #fff"
            >清除签名</a-button
          >
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {AppComponentBase} from "@/shared/component-base";
import {ModalHelper} from "@/shared/helpers";
import SetImg from "@/app/oa/Sign/SetImg/Index.vue";
import {SignServiceProxy} from "@/shared/service-proxies";

export default {
  name: "userInfo",
  mixins: [AppComponentBase],
  components: {
    SetImg,
  },
  data() {
    return {
      jobNumber: "",
      realName: "",
      tenantName: "",

      ImageID: "",
      Imgurl: "",
      IsHaveImg: false,
    };
  },
  created() {
    this._SignServiceProxy = new SignServiceProxy(this.$apiUrl, this.$api);
  },
  mounted() {
    console.log(abp);
    this.jobNumber = abp.userInfo.jobNumber;
    this.realName = abp.userInfo.realName;
    this.tenantName = abp.userInfo.tenantName;

    this.GetImageGuid();  
  },
  methods: {
    GetImageGuid() {
      this._SignServiceProxy.getSignGuid("").then((res) => {
        this.ImageID = res; // 返回 ******.png
        this.CheckImage();
      });
    },

    // 清空签名图片
    CleanImg() {
      var that = this;
      that.$confirm({
        title: "删除签名",
        content: "你确定要删除吗",
        cancelText: "取消",
        okText: "确定",
        onOk() {
          that._SignServiceProxy.cleanUserSignImage("").then((res) => {
            that.ImageID = "";
            that.CheckImage();
          });
        },
        onCancel() {},
      });
    },

    CheckImage() {
      // this._SignServiceProxy.checkImage(this.ImageID).then((res) => {
      //   this.IsHaveImg = res == 1;
      //   if (this.IsHaveImg) {
      //     this.Imgurl =
      //       AppConsts.remoteServiceBaseUrl +
      //       "/File/ShowSignImage?guid=" +
      //       this.ImageID;
      //   }
      // });
      if (this.ImageID != "") {
        //如果 有值 ，就说明OSS有图片，直接 显示
        this.IsHaveImg = true;
        var index = this.ImageID.indexOf("|");
        this.Imgurl = this.ImageID.substring(index + 1);       
      } else {
        this.IsHaveImg = false;
      }
    },

    SetImg() {
      ModalHelper.create(
        SetImg,
        {},
        {
          width: "900px",
        }
      ).subscribe((res) => {
        if (res != false) {
          this.ImageID = res;
          this.CheckImage();
        }
      });
    },
  },
};
</script>
<style>
.Info .ant-row {
  padding: 10px 0px;
  font-weight: 800;
}
</style>
