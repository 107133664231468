<template functional>
	<span v-if="props.data">
		<!-- <template v-if="props.data.type === 'icon'">
			<a-icon :type="props.data.value"></a-icon>
		</template> -->
		<!-- <IconFont v-else :type="props.data.value" :style="{ 'font-size': '18px' }"></IconFont> -->
		<IconFont :type="props.data.value" :style="{ 'font-size': '22px' }"></IconFont>
		<span class="nav-menu-text">
			{{props.text}}
		</span>
	</span>
	<span class="nav-menu-text" style="padding-left: 31px" v-else>
		{{props.text}}
	</span>
</template>

<script>
import {localizationService} from "@/shared/i18n";
import  {AppComponentBase}  from "@/shared/component-base";
export default {
	mixins: [AppComponentBase],
	name: 'nav-item-icon',
	props: ['data', 'text'],
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {

	},
};
</script>

<style lang="less" scoped>
/** 隐藏文字内容 */
.ant-menu-inline-collapsed {
	.nav-menu-text {
		display: none;
	}
}
</style>
