/**
 * 默认企业帮图标
 */
const defaultIcon="/assets/img/icons/favicon.ico";

/**
 * 修改网页Icon
 * @param ico地址
 */
function changeFavicon (link="") {
    if(!link){
        link=defaultIcon;
    }
    let $favicon = document.querySelectorAll("link[rel='icon']");
    if($favicon.length>0){
        $favicon.forEach(item=>{
            item.setAttribute("href",link);
        });
    }
    else {
        let $icon=document.createElement('link');
        $icon.type = 'image/x-icon';
        $icon.rel = 'shortcut icon';
        $icon.href = link;
        document.getElementsByTagName('head')[0].appendChild($icon);
    }
}

export default changeFavicon;
