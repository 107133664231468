import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const commonbllRouting: RouteConfig[] = [
    {
        path: 'commonbll',
        meta: { title: '业务字典' },
        component: LayoutBlock,
        redirect: '/app/commonbll/data-item',
        children: [
            {
                name: 'data-item',
                path: 'data-item',
                component: () => import(/* webpackChunkName: "admin" */ './data-item/data-item.vue'),
                meta: { title: '337' },
            },
        ],
    },
];

export default commonbllRouting;
