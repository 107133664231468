import localizationService from "../i18n/localization.service";
import abpService from "../abp/abp.service";
import apiHttpClient from "@/shared/utils/api-http-client";
import { AppConsts } from "@/abpPro/AppConsts";
import { intersectionBy } from "lodash";

const AppCompoentBase = {
  data() {
    return {
      loading: false,
      saving: false,
      isReady: false,

      //搜索筛选
      filterText: "",
      /**分页参数**/
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 条数显示范围
      pageRange: [1, 20],
      // 显示条数
      pageSizeOptions: ["10", "20", "50", "100"],
      /**分页参数**/

      /**分页接口基础参数**/
      request: {
        sorting: "",
        maxResultCount: 20,
        skipCount: 0,
      },

      //选择的行的对象
      selectedRowObj: {},
      //选择行的唯一标识字段名
      selectedRowField: "id",
      //表格是否多选
      isMultiple: false,
      scroll_x: 0,
    };
  },
  computed: {
    moduleKey() {
      return abpService.moduleKey;
    },
    $apiUrl() {
      // API 的 访问地址
      return AppConsts.remoteServiceBaseUrl;
    },
    OSSRequestUrl(){
      return AppConsts.OSSRequestUrl;
    },
    $api() {
      // API 的 http 实例
      return apiHttpClient;
    },
    $notificationCount() {
      return AppConsts.notificationCount;
    },
    message() {
      return abpService.abp.message;
    },
    notify() {
      return abpService.abp.notify;
    },
    //共多少页
    total() {
      return Math.ceil(this.totalItems / this.request.maxResultCount) || 1;
    },
    //分页文字
    showTotal() {
      return `${this.pageNumber}/${this.total}页，共${this.totalItems}条，当前
			${(this.pageNumber - 1) * this.request.maxResultCount + 1}-${
        this.pageNumber * this.request.maxResultCount
      }条`;
    },
    //当前选择的ID
    selectedRowKeys() {
      try {
        return this.selectedRows.map((r) => r[this.selectedRowField]);
      } catch (error) {
        console.error("computed selectedRowKeys", error);
        return [];
      }
    },
    selectedRows() {
      try {
        return Object.values(this.selectedRowObj).filter((r) => r.checked);
      } catch (error) {
        console.error("computed selectedRows", error);
        return [];
      }
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {},
        onSelect: (record) => {
          this.rowSelect(record);
        },

        onSelectAll: (selected, selectedRows, changeRows) => {
          //全选选中
          if (selectedRows.length > 0) {
            //轮循改变行，添加选中
            for (let row of changeRows) {
              //已选中的跳出
              if (row.checked) continue;
              this.rowSelect(row);
            }
          } else {
            //https://www.lodashjs.com/docs/lodash.intersectionBy
            //根据 id 获取两个数组对象中id相同的值，返回一个新数组
            //随后轮循数组执行取消选中
            intersectionBy(
              Object.values(this.selectedRowObj),
              changeRows,
              this.selectedRowField
            ).forEach((row) => {
              this.rowSelect(row);
            });
            // this.selectedRowObj = {};
          }
        },
      };
    },
    //列宽必须固定
    useDrag() {
      return {
        header: {
          cell: (h, props, children) => {
            const { key, ...restProps } = props;

            const col = this.columns.find((col) => {
              const k = col.dataIndex || col.key;
              return k === key;
            });

            if (!col || !col.width) {
              return h("th", { ...restProps }, [...children]);
            }

            const dragProps = {
              key: col.dataIndex || col.key,
              class: "table-draggable-handle",
              attrs: {
                w: 10,
                x: col.width,
                z: 1,
                axis: "x",
                draggable: true,
                resizable: true,
                preventDeactivation: true,
              },
              on: {
                dragging: (x, y) => {
                  col.width = Math.max(x, 1);
                },
                dragstop: (e) => {
                  console.log("e", e);
                  return false;
                },
                activated: (e) => {
                  console.log("ee", e);
                },
                click: (e) => {
                  console.log("click", e);
                },
              },
            };
            const drag = h("vue-draggable-resizable", { ...dragProps });
            return h("th", { ...restProps, class: "resize-table-th" }, [
              ...children,
              drag,
            ]);
          },
        },
      };
    },
  },
  methods: {
    customRow(record, index) {
      return {
        on: {
          click: () => {
            this.rowSelect(record);
          },
        },
      };
    },
    //执行选择
    rowSelect(record) {
      //是否多选
      if (this.isMultiple) {
        record.checked = !record.checked;
      } else {
        //重置所有行背景颜色
        this.selectedRowObj = {};
        //单选，如果当前已选中，则取消
        record.checked = true;
      }
      if (record.checked) {
        this.$set(
          this.selectedRowObj,
          "r" + record[this.selectedRowField],
          record
        );
        this.onRowSelect(record, "set");
      } else {
        if (this.selectedRowObj["r" + record[this.selectedRowField]]) {
          this.$delete(
            this.selectedRowObj,
            "r" + record[this.selectedRowField]
          );
          this.onRowSelect(record, "delete");
        }
      }
    },
    //执行清空
    rowClearAllSelected() {
      Object.values(this.selectedRowObj).forEach((row) => {
        this.rowSelect(row);
      });
      this.selectedRowObj = {};
      //触发回调
      this.onRowClearAllSelected();
    },
    //选择回调
    onRowSelect(record, type) {
      //console.log('表格行选择回调方法，按需重新此方法 onRowSelect(record, type)');
    },
    //清空回调
    onRowClearAllSelected() {
      //清空回调
    },
    rowClassName(record, index) {
      //如果是多选，不显示亮色
      if (!this.isMultiple) {
        if (this.selectedRowObj["r" + record[this.selectedRowField]]) {
          //类在 style/qyb.less
          return "selected";
        }
      }
    },
    l(key, ...args) {
      return localizationService.l(key, ...args);
    },
    /**
     * 判断是否有改按钮权限
     * @param mBtnPermissionName
     * @returns {boolean}
     */
    isGranted(mBtnPermissionName) {
      if (!mBtnPermissionName) {
        return true;
      }
      //租户管理员
      if (abpService.abp.userInfo.isTenantAdmin === 1) {
        return true;
      }
      //获取模块key
      if (abpService.abp.auth.moduleButtonDic && abpService.moduleKey) {
        if (abpService.abp.auth.moduleButtonDic[abpService.moduleKey]) {
          if (
            abpService.abp.auth.moduleButtonDic[abpService.moduleKey].indexOf(
              mBtnPermissionName
            ) !== -1
          ) {
            return true;
          }
        }
      }
      return false;
    },
    /**
     * 组合权限 逗号分割
     * @param permissions
     * @returns {boolean}
     */
    isGrantedAny(permissions) {
      if (!permissions) {
        return false;
      }
      for (const permission of permissions.split(",")) {
        if (this.isGranted(permission)) {
          return true;
        }
      }
      return false;
    },
    //搜索框点击事件
    search() {
      this.selectedRowObj = {};
      this.pageNumber = 1;
      this.request.skipCount = 0;
      this.getData();
    },
    refresh() {
      this.filterText = "";
      this.search();
    },
    getData() {
      console.warn("请在混入的页面重写该方法getData");
    },
    /**
     * 重置分页参数
     */
    resetRequest() {
      this.request = { maxResultCount: 20, skipCount: 0, sorting: "" };
      this.rowClearAllSelected();
    },
    /**
     * 分页
     */
    onPageChange(page) {
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    showPageSizeChange(current, size) {
      this.pageNumber = 1;
      this.request.maxResultCount = size;
      this.rowClearAllSelected();
      this.getData();
    },
    //排序
    sorterChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.request.sorting =
          sorter.columnKey + " " + sorter.order.replace("end", "");
      } else {
        this.request.sorting = undefined;
      }
      this.search();
    },
    /**
     * 获取当前页面的表格列权限
     * @returns 表格列
     */
    getTableColumn() {
      if (abpService.abp.auth.moduleColumnDic && abpService.moduleKey) {
        if (abpService.abp.auth.moduleColumnDic[abpService.moduleKey]) {
          return abpService.abp.auth.moduleColumnDic[abpService.moduleKey];
        }
      }
      return [];
    },
    /**
     * 获取表格列初始化
     * @param 表格名称
     * @param 列数据
     * @param 列默认宽度
     * returns {拥有权限的列}
     */
    getColumn(gridName, column, defaultWidth = 100) {
      this.scroll_x = 0;
      let data = [];
      let codes = [];
      if (column) {
        if (column.length <= 0) {
          return [];
        }
      }
      let columnData = column;

      if (gridName) {
        data = this.getTableColumn().filter(
          (item) => item.gridName == gridName
        );
      } else {
        data = this.getTableColumn();
      }
      codes = _.map(data, "enCode");
      //checkbox(默认的勾选框)
      let c = [
        ...columnData.filter(
          (item) =>
            codes.includes(item.dataIndex) || item.dataIndex == "checkbox"
        ),
      ];
      c.forEach((item) => {
        if (item.width) {
          this.scroll_x += parseInt(item.width);
        } else {
          this.scroll_x += defaultWidth;
        }
      });

      return c;
    },
    /**
     * 获取合并列的表格列数据(合并表头表格)
     * @param gridName
     * @param column
     * @return {*[]|列}
     */
    getColSpanColumn(gridName, column) {
      this.scroll_x = 0;
      let data = [];
      if (column) {
        if (column.length <= 0) {
          return [];
        }
      }
      let columnData = column;
      if (gridName) {
        data = this.getTableColumn().filter(
          (item) => item.gridName == gridName
        );
      } else {
        data = this.getTableColumn();
      }
      let codes = _.map(data, "enCode");
      return this.getChildrenColumn(columnData, codes, { removeCount: 0 });
    },
    /**
     * 获取合并列的表格列数据(递归子级)
     * @param 列数据源
     * @param 需要的列codes
     * @param 引用类型对象(记录移除的大类型数)
     * @param 列默认宽度
     * @return 列
     */
    getChildrenColumn(data, codes, obj, defaultWidth = 100) {
      data.forEach((item, index) => {
        if (item.children) {
          if (item.children.length > 0) {
            //优先查找子级
            item.children = this.getChildrenColumn(
              item.children,
              codes,
              obj,
              defaultWidth
            );
          }
          //没有子级的大类(移除)
          if (item.children.length <= 0) {
            data = [...data.filter((t, i) => t.title != item.title)];
            obj.removeCount += 1;
          }
        } else {
          //最后子级，不存在权限，移除
          if (!codes.includes(item.dataIndex)) {
            data = [...data.filter((t) => t.dataIndex != item.dataIndex)];
          } else {
            if (item.width) {
              this.scroll_x += parseInt(item.width);
            } else {
              this.scroll_x += defaultWidth;
            }
          }
        }
      });
      return data;
    },
  },
};

export default AppCompoentBase;
