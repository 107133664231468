<template functional>
    <a-sub-menu :key="props.menuInfo.key">
        <span slot="title">
            <span v-if="props.menuInfo.icon">
                <IconFont
                    :type="props.menuInfo.icon.value"
                    :style="{ 'font-size': '22px' }"
                ></IconFont>
                <span class="nav-menu-text">
					{{ props.l('Menu.Web.'+props.menuInfo.key)   }}
                </span>
            </span>
            <span class="nav-menu-text" v-else>
                <span style="padding-left: 31px">
                    {{ props.l('Menu.Web.'+props.menuInfo.text)  }} 
                </span>
            </span>
        </span>

        <template v-for="item in props.menuInfo.children">
            <a-menu-item
                :key="item.key"
                v-if="item.children && item.children.length > 0 ? false : true"
                :title="props.l(item.key)"
                @click="props.click(item)"
            >
                <span v-if="item.icon">
                    <span class="nav-menu-text">
                        <IconFont
                            :type="item.icon.value"
                            :style="{ 'font-size': '22px' }"
                        ></IconFont>
						{{ props.l('Menu.Web.'+ item.key) }}
                    </span>
                </span>
                <span class="nav-menu-text" v-else>
                    <span style="padding-left: 31px">
						{{ props.l( 'Menu.Web.'+item.key) }}
                    </span>
                </span>
            </a-menu-item>

            <sub-menu-item
                :key="item.key"
                :menu-info="item"
                v-if="item.children && item.children.length > 0 ? true : false"
            />
        </template>
    </a-sub-menu>
</template>
<script>
import NavItemIcon from "./nav-item-icon";
import { abpService } from "@/shared";
import {localizationService} from "@/shared/i18n";
// import  {AppComponentBase}  from "@/shared/component-base";
export default {
	
    props: {
        menuInfo: {
            type: Object,
            default: {},
        },
        /**
         * 虚假Props事件,这里只是为了拿到回调
         */
        click: {
            type: Function,
            default: (item) => {
                //触发事件,点击菜单时触发父级的回调
                abpService.abp.event.trigger("menuItemClick", item);
            },
        },
        l: {
            type: Function,
            default: (str) => {
                return localizationService.l(str);
            },
        },
    },
    components: { NavItemIcon },
    data() {
        return {};
    },
    mounted() {},
    created() {},
    methods: {},
	created() {
	},
	methods:{
	}
};
</script>
