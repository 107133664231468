import { LayoutBlock } from "@/layout";
import { RouteConfig } from "vue-router";

const AttendanceRouting: RouteConfig[] = [
  {
    path: "attendance",
    meta: { title: "班次" },
    component: LayoutBlock,
    redirect: "/app/attendance/shiftinfo",
    children: [
      {
        name: "shiftinfo",
        path: "shiftinfo",
        component: () => import("./shiftinfo/shiftinfo.vue"),
        meta: { title: "349" },
      },
      {
        name: "attendpeopleconfig",
        path: "attendpeopleconfig",
        component: () => import("./attendpeopleconfig/index.vue"),
        meta: { title: "考勤人员设置" },
      },
      {
        name: "attendance-group",
        path: "attendance-group",
        component: () => import("./attendance-group/attendance-group.vue"),
        meta: { title: "323" },
      },
      {
        name: "holiday-rules",
        path: "holiday-rules",
        component: () => import("./holiday-rules/holiday-rules.vue"),
        meta: { title: "320" },
      },
      {
        name: "attendance-setting",
        path: "attendance-setting",
        component: () => import("./attendance-setting/attendance-setting.vue"),
        meta: { title: "322" },
      },
      {
        name: "user-attendance-plan",
        path: "user-attendance-plan",
        component: () =>
          import("./user-attendance-plan/user-attendance-plan.vue"),
        meta: { title: "324" },
      },
      {
        name: "attmachinedeploy",
        path: "attmachinedeploy",
        component: () => import("./attmachinedeploy/attmachinedeploy.vue"),
        meta: { title: "322" },
      },
      {
        name: "hit-time",
        path: "hit-time",
        component: () => import("./hit-time/hit-time.vue"),
        meta: { title: "打卡时间" },
      },
      {
        name: "business-calendar",
        path: "business-calendar",
        component: () => import("./businessCalendar/businessCalendar.vue"),
        meta: { title: "258" },
      },
      {
        name: "leave-card-overtime-detailed",
        path: "leave-card-overtime-detailed",
        component: () =>
          import(
            "./leave-card-overtime-detailed/leave-card-overtime-detailed.vue"
          ),
        meta: { title: "293" },
      },
      {
        name: "check-in-record",
        path: "check-in-record",
        component: () => import("./check-in-record/check-in-record.vue"),
        meta: { title: "326" },
      },
      {
        name: "daily-summary",
        path: "daily-summary",
        component: () => import("./daily-summary/daily-summary.vue"),
        meta: { title: "328" },
      },
      {
        name: "user-leave-balance",
        path: "user-leave-balance",
        component: () => import("./user-leave-balance/user-leave-balance.vue"),
        meta: { title: "307" },
      },
      {
        name: "month-summary",
        path: "month-summary",
        component: () => import("./month-summary/month-summary.vue"),
        meta: { title: "Menu.Web.329" },
      },
      {
        name: "replenish-card",
        path: "replenish-card",
        component: () => import("./replenish-card/replenish-card.vue"),
        meta: { title: "325" },
      },
      {
        name: "work-overtime",
        path: "work-overtime",
        component: () => import("./work-overtime/work-overtime.vue"),
        meta: { title: "327" },
      },
      {
        name: "ask-for-leave",
        path: "ask-for-leave",
        component: () => import("./ask-for-leave/ask-for-leave.vue"),
        meta: { title: "321" },
      },
      {
        name: "excess-max-overtime-record",
        path: "excess-max-overtime-record",
        component: () =>
          import("./excess-max-overtime-record/excess-max-overtime-record.vue"),
        meta: { title: "277" },
      },
      {
        name: "daily-myerror",
        path: "daily-myerror",
        component: () => import("./daily-myerror/daily-myerror.vue"),
        meta: { title: "我的考勤异常" },
      },
      {
        name: "daily-my-subordinate",
        path: "daily-my-subordinate",
        component: () =>
          import("./daily-my-subordinate/daily-my-subordinate.vue"),
        meta: { title: "下属当月考勤异常" },
      },
      {
        name: "att-error",
        path: "att-error",
        component: () => import("./daily-myerror/att-error.vue"),
        meta: { title: "300" },
      },
      {
        name: "week-summary",
        path: "week-summary",
        component: () => import("./week-summary/week-summary.vue"),
        meta: { title: "344" },
      },
      {
        name: "empQuotaRecordList",
        path: "empQuotaRecordList",
        component: () => import("./empQuotaRecordList/empQuotaRecordList.vue"),
        meta: { title: "307" },
      },
      {
        name: "minerThreeDays",
        path: "minerThreeDays",
        component: () => import("./minerThreeDays/Index.vue"),
        meta: { title: "247" },
      },
    ],
  },
];

export default AttendanceRouting;
