import {AppConsts} from '@/abpPro/AppConsts';
import {watermarkIt} from 'easy-watermark';


export default function setWatermark() {
    watermarkIt({
        el: '.watermark-container', // a DOM selector (for all nodes with this selector) or a DOM Object
        text: `${AppConsts.waterMarkerText} ${new Date().toLocaleString()}`, // watermark text
        width: 600,  // image width, optional,200 defaulted
        height: 400, // image height,optional, 200 defaulted
        fontSize: 14, // text font size, optional,1 defaulted
        color: 'rgba(238,238,238,0.5)',  // text color, optional, 'rgba(0, 0, 0, .5)' defaulted
    });
}


