import { RouteConfig } from "vue-router";
import { LayoutDefault } from "@/layout";
import { mainRouting } from "./main";
import { adminRouting } from "./admin";
import { organizationRouting } from "./organization";
import commonbllRouting from "@/app/commonbll/commonbll.routing";
import { processappRouting } from "@/app/processapp";
import AppManageRouting from "@/app/appmanage/appmanage.routing";
import OARouting from "@/app/oa/oa.routing";
import hrRouting from "@/app/hr/hr.routing";
import AttendanceRouting from "@/app/attendance/attendance.routing";
import CustomizeReportRouting from "@/app/customizereport/customizereport.routing";
import { SalaryRouting } from "@/app/salary";
import MessageManageRouting from "@/app/message-manage/message-manage.routing";
import TaskSystemRouting from "@/app/task-system/task-system.routing";
import LaborcostRouting from "@/app/laborcost/laborcost.routing";
import DatabaseManageRouting from "@/app/database-manage/database-manage.routing";
import ControlledfilesRouting from "@/app/controlledfiles/controlledfiles.routing";
import SystemAdminIstrationRouting from "@/app/systemadministration/systemadministration.routing";
import AppSystemAdminIstrationRouting from "@/app/appsystemadministration/appsystemadministration.routing";
import CsRouting from "@/app/CS/Cs.routing";
import SuggestionBoxRouting from "@/app/suggestionbox/suggerstonbox.routing";
import RecruitRouting from "@/app/recruit/recruit.routing";
import syncqywxrouting from "@/app/sync-qywx/sync-qywx.routing";
import externalapprouting from "@/app/externalapp/externalapp.routing"
import costcenterrouting from"@/app/costcenter/costcenter.routing";
import corporationrouting from"@/app/corporation/corporation.routing";
import costcenterdeprouting from"@/app/costcenterdep/costcenterdep.routing";
import costcenteremprouting from"@/app/costcenteremp/costcenteremp.routing";
import apidatasourcerouting from "@/app/apidatasource/apidatasource.routing"
const appRouting: RouteConfig[] = [
  {
    path: "/app",
    component: LayoutDefault,
    redirect: "/app/main",
    children: [
      ...mainRouting,
      ...adminRouting,
      ...organizationRouting,
      ...commonbllRouting,
      ...processappRouting,
      ...AppManageRouting,
      ...OARouting,
      ...hrRouting,
      ...AttendanceRouting,
      ...CustomizeReportRouting,
      ...SalaryRouting,
      ...MessageManageRouting,
      ...TaskSystemRouting,
      ...LaborcostRouting,
      ...DatabaseManageRouting,
      ...ControlledfilesRouting,
      ...SystemAdminIstrationRouting,
      ...CsRouting,
      ...AppSystemAdminIstrationRouting,
      ...SuggestionBoxRouting,
      ...RecruitRouting,
      ...syncqywxrouting,
      ...externalapprouting,
      ...costcenterrouting,
      ...corporationrouting,
      ...costcenterdeprouting,
      ...costcenteremprouting,
      ...corporationrouting,
      ...apidatasourcerouting
    ],
  },
  {
    path: "/mobilepage",
    meta: { title: "系统意见箱" },
    component: () => import("@/app/suggestionbox/mobilepage/mobilepage.vue"),
  },
  {
    path: "/desing-page-copy",
    meta: { title: "系统意见箱" },
    component: () =>
      import("@/app/suggestionbox/desing-page-copy/desing-page-copy.vue"),
  },
];

export default appRouting;
