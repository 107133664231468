<template>
  <div>
    <a-spin :spinning="spinning">
      <!-- 标题 -->
      <div class="modal-header" style="margin-bottom: 3px">
        <div class="modal-title">
          <span>设置签名</span>
        </div>
      </div>

      <a-tabs type="card" @change="callback">
        <a-tab-pane key="1" tab="图片上传">
          <div style="display: flex">
            <div style="flex: 1" class="cut">
              <vue-cropper
                ref="cropper"
                :img="option.img"
                :output-size="option.size"
                :output-type="option.outputType"
                :info="true"
                :full="option.full"
                :can-move="option.canMove"
                :can-move-box="option.canMoveBox"
                :fixed-box="option.fixedBox"
                :original="option.original"
                :auto-crop="option.autoCrop"
                :auto-crop-width="option.autoCropWidth"
                :auto-crop-height="option.autoCropHeight"
                :center-box="option.centerBox"
                @real-time="realTime"
                :high="option.high"
              ></vue-cropper>
            </div>
            <div style="width: 250px; text-align: center">
              <div
                class="show-preview"
                :style="{
                  width: previews.w + 'px',
                  height: previews.h + 'px',
                  overflow: 'hidden',
                  margin: '0px auto',
                }"
              >
                <div :style="previews.div">
                  <img :src="previews.url" :style="previews.img" />
                </div>
              </div>

              <!-- <div>
            <a @click="down('base64')" class="btn">download(base64)</a>           
          </div> -->
            </div>
          </div>

          <div style="display: flex">
            <div style="flex: 1; display: flex">
              <div style="flex: 1">
                <label class="btn" for="uploads">上传图片</label>
                <input
                  type="file"
                  id="uploads"
                  style="position: absolute; clip: rect(0 0 0 0)"
                  accept="image/png, image/jpeg, image/gif, image/jpg"
                  @change="uploadImg($event, 1)"
                />
              </div>
              <div style="flex: 1; text-align: right">
                <button @click="changeScale(1)" class="btn">放大</button>
                <button @click="changeScale(-1)" class="btn">缩小</button>
                <button @click="rotateLeft" class="btn">左旋</button>
                <button
                  @click="rotateRight"
                  class="btn"
                  style="margin-right: 0px"
                >
                  右旋
                </button>
              </div>
            </div>
            <div style="width: 250px; text-align: center">
              <button @click="saveimg" class="btn">保存签名</button>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="手写签名">
          <vue-esign
            ref="esign"
            style="border: 1px solid #ccc"
            :width="800"
            :height="300"
            :isCrop="isCrop"
            :lineWidth="lineWidth"
            :lineColor="lineColor"
            :bgColor.sync="bgColor"
          />

          <div style="text-align: center">
            <button class="btn" @click="handleReset">清空画板</button>
            <button class="btn" @click="handleGenerate">保存签名</button>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-spin>
  </div>
</template>

<script>
import ModalComponentBase from "@/shared/component-base/modal-component-base";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import VueCropper from "@/components/cropper/vue-cropper.vue";
import { SignServiceProxy } from "@/shared/service-proxies";
import vueEsign from "@/components/vue-sign/index";
export default {
  mixins: [ModalComponentBase],
  components: {
    VueCropper,
    vueEsign,
  },
  created() {
    this.fullData(); // 模态框必须,填充数据到data字段
    this._SignServiceProxy = new SignServiceProxy(this.$apiUrl, this.$api);
  },
  data() {
    return {
      spinning: false,
      zhCN,

      lineWidth: 2,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,

      previews: {},
      option: {
        img: "",
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 200,
        autoCropHeight: 75,
        centerBox: false,
        high: true,
      },
    };
  },
  methods: {
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          var d = {};
          d.Base64Data = res;
          this._SignServiceProxy
            .saveImg(d)
            .finally(() => {
              this.spinning = false;
            })
            .then((res) => {
              this.success(res);
            });
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },

    // 实时预览函数
    realTime(data) {
      console.log(data);
      this.previews = data;
    },
    uploadImg(e, num) {
      //上传图片
      // this.option.img
      var file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("图片类型必须是.gif,jpeg,jpg,png,bmp中的一种");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        if (num === 1) {
          this.option.img = data;
        } else if (num === 2) {
          this.example2.img = data;
        }
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(file);
    },
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    down(type) {
      // event.preventDefault()
      var aLink = document.createElement("a");
      aLink.download = "demo";
      // 输出
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.downImg = window.URL.createObjectURL(data);
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          this.downImg = data;
          aLink.href = data;
          aLink.click();
        });
      }
    },
    saveimg() {
      console.log(process.env);
      this.$refs.cropper.getCropData((data) => {
        console.log(data);
        var d = {};
        d.Base64Data = data;
        this._SignServiceProxy
          .saveImg(d)
          .finally(() => {
            this.spinning = false;
          })
          .then((res) => {
            this.success(res);
          });
      });
    },
  },
};
</script>

<style>
.cut {
  height: 350px;
}

.btn {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #c0ccda;
  color: #1f2d3d;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 5px 10px 0px 0px;
  padding: 9px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #50bfff;
  border-color: #50bfff;
  transition: all 0.2s ease;
  text-decoration: none;
  user-select: none;
}
</style>