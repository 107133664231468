import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const costcenterdeprouting: RouteConfig[] = [
  {
    path: "costcenterdep",
    meta: { title: "成本中心" },
    component: LayoutBlock,
    redirect: "/app/costcenterdep/costcenterdep-page",
    children: [
      {
        name: "costcenterdep-page",
        path: "costcenterdep-page",
        component: () => import("./costcenterdep-page/costcenterdep-page.vue"),
        meta: { title: "costcenterdep-page" },
      },
    ],
  },
];
export default costcenterdeprouting;