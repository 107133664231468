import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const SalaryRouting: RouteConfig[] = [
    {
        path: 'salary',
        meta: { title: '基础资料' },
        component: LayoutBlock,
        redirect: '/app/salary/base-info',
        children: [
            {
                name: 'base-info',
                path: 'base-info',
                component: () => import('./base-info/base-info.vue'),
                meta: { title: '352' },
            },
            {
                name: 'fixed-info',
                path: 'fixed-info',
                component: () => import('./fixed-info/fixed-info.vue'),
                meta: { title: '固定项' },
            },
            {
                name: 'social-security-base',
                path: 'social-security-base',
                component: () => import('./social-security-base/social-security-base.vue'),
                meta: { title: '社保公积金维护' },
            },
            {
                name: 'salary-change',
                path: 'salary-change',
                component: () => import('./salary-change/salary-change.vue'),
                meta: { title: '351' },
            },
            {
                name: 'salary-change-detail',
                path: 'salary-change-detail',
                component: () => import('./salary-change-detail/salary-change-detail.vue'),
                meta: { title: '350' },
            },
            {
                name: 'month-calc-item',
                path: 'month-calc-item',
                component: () => import('./month-calc-item/month-calc-item.vue'),
                meta: { title: '薪资月度计算项' },
            },
            {
                name: 'salary-detail',
                path: 'salary-detail',
                component: () => import('./salary-detail/salary-detail.vue'),
                meta: { title: '358' },
            },
            {
                name: 'ss-rules',
                path: 'ss-rules',
                component: () => import('./ss-rules/ss-rules.vue'),
                meta: { title: '359' },
            },
            {
                name: 'salary-setting',
                path: 'salary-setting',
                component: () => import('./salary-setting/salary-setting.vue'),
                meta: { title: '301' },
            },
            {
                name: 'salary-record',
                path: 'salary-record',
                component: () => import('./salary-record/salary-record.vue'),
                meta: { title: '343' },
            },
            {
                name: 'send-salary-record',
                path: 'send-salary-record',
                component: () => import('./send-salary-record/send-salary-record.vue'),
                meta: { title: '287' },
            },
            {
                name: 'five-insurances-and-one-fund',
                path: 'five-insurances-and-one-fund',
                component: () => import('./five-insurances-and-one-fund/five-insurances-and-one-fund.vue'),
                meta: { title: '355' },
            }
        ],
    },
];

export default SalaryRouting;
