import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const mainRouting: RouteConfig[] = [
    {
        path: 'main',
        component: LayoutBlock,
        meta: { title: '系统' },
        redirect: '/app/main/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: "main" */ './dashboard/dashboard.vue'),
                meta: { title: '工作台' },
            },
        ],
    },
];

export default mainRouting;
