<template>
    <router-view />
</template>

<script>
export default {};
</script>
<style lang="less">
#app {
    height: 100%;
}

</style>
