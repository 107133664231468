<template>
    <a-spin :spinning="loading">
        <div class="container">
            <div class="n-header">
                <a @click="notificationsColse" title="点击关闭">
                    <a-icon type="close" />
                </a>
            </div>
            <div class="n-body-header" v-if="contactsData.length > 0">
                <div style="flex: 2"></div>
                <div class="n-message">
                    <div class="e-message-icon">
                        <span><a-icon type="message" style="font-size: 20px;color: #5caeff"/></span>
                        <span class="n-message-otherName">{{ otherName }}</span>
                    </div>
                </div>
            </div>
            <div class="n-body">
                <div class="mes-empty" v-if="contactsData.length <= 0">
                    暂无消息
                </div>
                <div class="n-body" v-else>
                    <div class="n-contacts">
                        <div class="contacts-data">
                            <div
                                class="contacts-data-row "
                                v-for="(item, key) in contactsData"
                                :class="{
                                    'contacts-bg-off': hasOtherId(item.otherId),
                                    'contacts-bg-on': hasOtherId(item.otherId),
                                }"
                                :key="key"
                                @click="contactsSelect(item)"
                            >
                                <a-badge :count="item.unReadNum" v-if="item.otherIcon" class="Mebadge">
                                    <icon-font :type="item.otherIcon"  v-if="item.otherName" :title="item.otherName" class="contacts-data-icon"></icon-font>
<!--                                    <a-icon type="message" class="contacts-data-icon" :title="item.otherName" />-->
                                </a-badge>
                                <a-badge :dot="item.unReadNum > 0" v-else>
                                    <div
                                        style="width: 75px;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 1;
                                      -webkit-box-orient: vertical;
                                      text-align: center;
                                    "
                                        :title="item.otherName"
                                    >
                                        {{ item.otherName }}
                                    </div>
                                </a-badge>
                            </div>
                        </div>
                    </div>
                    <div class="n-body-message">
                        <div class="n-body-message-data">
                            <div v-if="messagesData.length <= 0" class="mes-empty">暂无数据</div>
                            <message-log v-for="item in messagesData" :key="item.id" :logs="item" v-else></message-log>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="contactsData.length > 0">
                <div class="n-body-message-seemore">
                    <a @click="historyMessage" title="查看当前消息记录">
                        <a-icon type="zoom-in" />
                        查看当前消息记录
                    </a>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import { ContactsServiceProxy, MessageServiceProxy } from '@/shared';
import MessageLog from './components/message-log';
import moment from 'moment';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import MessageRecord from './message-record/message-record';

export default {
    name: 'notifications-model',
    mixins: [AppComponentBase],
    components: { MessageLog, MessageRecord },
    data() {
        return {
            contactsData: [],
            otherId: undefined,
            messagesData: [],
            otherName: undefined,
        };
    },
    created() {
        this._contactsServiceProxy = new ContactsServiceProxy(this.$apiUrl, this.$api);
        this._messageServiceProxy = new MessageServiceProxy(this.$apiUrl, this.$api);
    },
    mounted() {
        this.getContacts();
    },
    methods: {
        /**
         * 获取联系人
         */
        getContacts() {
            this.loading = true;
            this._contactsServiceProxy
                .getMyContacts()
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.contactsData = res;
                    if (this.contactsData.length > 0) {
                        this.contactsSelect(this.contactsData[0]);
                    }
                });
        },
        /**
         * 获消息记录
         */
        getHistoryMessages() {
            this.loading = true;
            this._messageServiceProxy
                .getHistoryByOtherUserId(this.otherId, 2, undefined, undefined, 10, undefined)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.messagesData = res.items;
                    this.messagesData.map((item, index) => {
                        this.canShowTime(index);
                        item.creationTime = item.creationTime ? this.getShowTime(item.creationTime) : '';
                    });
                });
        },
        hasOtherId(record) {
            if (record == this.otherId) {
                return true;
            } else {
                return false;
            }
        },
        contactsSelect(record) {
            record.unReadNum = 0;
            this.otherName = record.otherName;
            this.otherId = record.otherId;
            if (this.otherId) {
                this.getHistoryMessages();
            }
        },
        notificationsColse() {
            this.$emit('notificationsColse', false);
        },
        /**
         * 查看当前消息记录
         */
        historyMessage() {
            modalHelper
                .create(
                    MessageRecord,
                    {
                        otherId: this.otherId,
                        otherName: this.otherName,
                    },
                    {
                        width: '800px',
                    }
                )
                .subscribe((res) => {});
        },
        //是否可以显示消息时间
        canShowTime(index) {
            if (index >= this.messagesData.length - 1) {
                return;
            }
            const current = this.messagesData[index].creationTime;
            const prev = this.messagesData[index + 1].creationTime;
            const diff = moment(new Date(current)).diff(moment(new Date(prev)), 'minutes');
            let time = new Date(current);
            const days = moment().diff(
                moment(new Date(`${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`)),
                'days'
            );
            // if(days<1){
            if (diff < 5) {
                this.messagesData[index].creationTime = undefined;
            }
            // }
        },
        //获取应该显示的消息时间
        getShowTime(time) {
            if (!time) return '';
            time = new Date(time);
            const days = moment().diff(
                moment(new Date(`${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()}`)),
                'days'
            );
            if (days == 1) {
                return moment(time).calendar();
            } else if (days > 1) {
                return moment(time).format('YYYY-MM-DD HH:mm:ss');
            } else {
                return moment(time).format('HH:mm');
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import 'notifications-model.less';

.contacts-data-row .ant-badge-dot {
    top: -7px;
    right: 1px;
}
.ant-badge-count {
    transform: scale(0.83, 0.83);
    font-size: 10px;
    top: -5px;
    right: -14px;
}
</style>
