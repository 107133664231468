import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const corporationrouting: RouteConfig[] = [
  {
    path: "corporation",
    meta: { title: "成本中心" },
    component: LayoutBlock,
    redirect: "/app/corporation/corporation-page",
    children: [
      {
        name: "corporation-page",
        path: "corporation-page",
        component: () => import("./corporation-page/corporation.vue"),
        meta: { title: "corporation-page" },
      },
    ],
  },
];
export default corporationrouting;