import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const apidatasourcerouting: RouteConfig[] = [
  {
    path: "apidatasource",
    meta: { title: "apidatasource" },
    component: LayoutBlock,
    redirect: "/app/apidatasource/apidatasource-page",
    children: [
      {
        name: "apidatasource-page",
        path: "apidatasource-page",
        component: () => import("./apidatasource-page/apidatasource-page.vue"),
        meta: { title: "apidatasource" },
      },
    ],
  },
];
export default apidatasourcerouting;