import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";





const RecruitRouting: RouteConfig[] = [
  {
    path: "recruit",
    meta: { title: "招聘" },
    component: LayoutBlock,
    redirect: "/app/recruit/recruit",
    children: [
      {
        name: "interview",
        path: "interview",
        component: () => import("./interview/interview.vue"),
        meta: { title: "Menu.Web.674" },
      },
      {
        name: "registrationform",
        path: "registrationform",
        component: () => import("./registrationform/registrationform.vue"),
        meta: { title: "Menu.Web.677" },
      },
      {
        name: "resume",
        path: "resume",
        component: () => import("./resume/Index.vue"),
        meta: { title: "Menu.Web.678" },
      },
      {
        name: "pre-entry",
        path: "pre-entry",
        component: () => import("./pre-entry/pre-entry.vue"),
        meta: { title: "Menu.Web.681" },
      }
    ],
  },
];
export default RecruitRouting;
