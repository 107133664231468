import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const approvalReport = [
	{
		name: 'checkprogress',
		path: 'checkprogress',
		component: () => import('./approval-report/checkprogress/checkprogress.vue'),
		meta: { title: '370' },
	},
	{
		name: 'zjjb',
		path: 'zjjb',
		component: () => import('./approval-report/zjjb/zjjb.vue'),
		meta: { title: '367' },
	},
	{
		name: 'qj',
		path: 'qj',
		component: () => import('./approval-report/qj/qj.vue'),
		meta: { title: '366' },
	},
	{
		name: 'jb',
		path: 'jb',
		component: () => import('./approval-report/jb/jb.vue'),
		meta: { title: '365' },
	},
	{
		name: 'bk',
		path: 'bk',
		component: () => import('./approval-report/bk/bk.vue'),
		meta: { title: '364' },
	},
	{
		name: 'wc',
		path: 'wc',
		component: () => import('./approval-report/wc/wc.vue'),
		meta: { title: '363' },
	},
	{
		name: 'xj',
		path: 'xj',
		component: () => import('./approval-report/xj/xj.vue'),
		meta: { title: '362' },
	},
	{
		name: 'hrzb',
		path: 'hrzb',
		component: () => import('./approval-report/hrzb/hrzb.vue'),
		meta: { title: '361' },
	},
	{
		name: 'sbwx',
		path: 'sbwx',
		component: () => import('./approval-report/sbwx/sbwx.vue'),
		meta: { title: '360' },
	},
	{
		name: 'dynamicprogress',
		path: 'dynamicprogress',
		component: () => import('./approval-report/dynamicprogress/dynamicprogress.vue'),
		meta: { title: '345' },
	},
	{
		name: 'startprocess',
		path: 'startprocess',
		component: () => import('./startprocess/Index.vue'),
		meta: { title: '276' },
	}
];

const oaReport = [
	{
		name: 'employee-count',
		path: 'employee-count',
		component: () => import('./oa-report/employee-count/employee-count.vue'),
		meta: { title: '251' },
	},
	{
		name: 'employee-count-analysis',
		path: 'employee-count-analysis',
		component: () => import('./oa-report/employee-count-analysis/employee-count-analysis.vue'),
		meta: { title: '250' },
	},
	{
		name: 'employee-quit-report',
		path: 'employee-quit-report',
		component: () => import('./oa-report/employee-quit-report/employee-quit-report.vue'),
		meta: { title: '331' },
	},
	{
		name: 'new-employee-report',
		path: 'new-employee-report',
		component: () => import('./oa-report/new-employee-report/new-employee-report.vue'),
		meta: { title: '新进人员报表' },
	},
	{
		name: 'contractdetail',
		path: 'contractdetail',
		component: () => import('./oa-report/contractdetail/contractdetail.vue'),
		meta: { title: '332' },
	},
	{
		name: 'employeeconfirmation',
		path: 'employeeconfirmation',
		component: () => import('./oa-report/employeeconfirmation/employeeconfirmation.vue'),
		meta: { title: '333' },
	},
];

const empReport = [
	{
		name: 'emp-gender-report',
		path: 'emp-gender-report',
		component: () => import('./emp-report/emp-gender-report/emp-gender-report.vue'),
		meta: { title: '244' },
	},
	{
		name: 'emp-type-report',
		path: 'emp-type-report',
		component: () => import('./emp-report/emp-type-report/emp-type-report.vue'),
		meta: { title: '243' },
	},
	{
		name: 'emp-educations-report',
		path: 'emp-educations-report',
		component: () => import('./emp-report/emp-educations-report/emp-educations-report.vue'),
		meta: { title: '242' },
	},
	{
		name: 'emp-work-age-report',
		path: 'emp-work-age-report',
		component: () => import('./emp-report/emp-work-age-report/emp-work-age-report.vue'),
		meta: { title: '241' },
	},
	{
		name: 'emp-age-report',
		path: 'emp-age-report',
		component: () => import('./emp-report/emp-age-report/emp-age-report.vue'),
		meta: { title: '270' },
	},
	{
		name: 'emp-province-report',
		path: 'emp-province-report',
		component: () => import('./emp-report/emp-province-report/emp-province-report.vue'),
		meta: { title: '271' },
	},
];

const salaryReport = [
	{
		name: 'salary-month-diff',
		path: 'salary-month-diff',
		component: () => import('./salary-report/salary-month-diff/salary-month-diff.vue'),
		meta: { title: '月度工资差异' },
	},
	{
		name: 'salary-struct',
		path: 'salary-struct',
		component: () => import('./salary-report/salary-struct/salary-struct.vue'),
		meta: { title: '286' },
	},
	{
		name: 'sum-salary',
		path: 'sum-salary',
		component: () => import('./salary-report/sum-salary/sum-salary.vue'),
		meta: { title: '285' },
	},
	{
		name: 'responsibility-system',
		path: 'responsibility-system',
		component: () => import('./salary-report/proportion-labor-cost/responsibility-system.vue'),
		meta: { title: '289' },
	},
	{
		name: 'proportion-labor-cost',
		path: 'proportion-labor-cost',
		component: () => import('./salary-report/proportion-labor-cost/proportion-labor-cost.vue'),
		meta: { title: '291' },
	},
	{
		name: 'non-proportion-labor-cost',
		path: 'non-proportion-labor-cost',
		component: () => import('./salary-report/proportion-labor-cost/non-proportion-labor-cost.vue'),
		meta: { title: '290' },
	},
	{
		name: 'direct-and-indirect',
		path: 'direct-and-indirect',
		component: () => import('./salary-report/proportion-labor-cost/direct-and-indirect.vue'),
		meta: { title: '304' },
	},
	{
		name: 'salary-payslip',
		path: 'salary-payslip',
		component: () => import('./salary-report/salary-payslip/salary-payslip.vue'),
		meta: { title: '292' },
	},
	{
		name: 'dep-payslip',
		path: 'dep-payslip',
		component: () => import('./salary-report/dep-payslip/dep-payslip.vue'),
		meta: { title: '281' },
	},
];

//实时考勤汇总报表
const attRealSumReport = [
	{
		name: 'day-sum',
		path: 'day-sum',
		component: () => import('./att-report/real-sum/day-sum.vue'),
		meta: { title: '日累计' },
	},
	{
		name: 'week-sum',
		path: 'week-sum',
		component: () => import('./att-report/real-sum/week-sum.vue'),
		meta: { title: '周累计' },
	},
	{
		name: 'month-sum',
		path: 'month-sum',
		component: () => import('./att-report/real-sum/month-sum.vue'),
		meta: { title: '月累计' },
	},
];

const CustomizeReportRouting: RouteConfig[] = [
	{
		name: 'approval',
		path: 'approval',
		meta: { title: '370' },
		component: LayoutBlock,
		redirect: '/app/approval/checkprogress',
		children: approvalReport,
	},
	{
		name: 'oa',
		path: 'oa',
		meta: { title: '251' },
		component: LayoutBlock,
		redirect: '/app/oa/employee-count',
		children: oaReport,
	},
	{
		name: 'emp-report',
		path: 'emp-report',
		meta: { title: '人员结构分析报表' },
		component: LayoutBlock,
		redirect: '/app/emp-report/emp-gender-report',
		children: empReport,
	},
	{
		name: 'salary-report',
		path: 'salary-report',
		meta: { title: '人员结构分析报表' },
		component: LayoutBlock,
		redirect: '/app/salary-report/salary-month-diff',
		children: salaryReport,
	},
	{
		name: 'att-report',
		path: 'att-report',
		meta: { title: '实时考勤汇总' },
		component: LayoutBlock,
		redirect: '/app/att-report/day-sum',
		children: attRealSumReport,
	},
];

export default CustomizeReportRouting;
