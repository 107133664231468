import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const CsRouting: RouteConfig[] = [
  {
    path: "Cs",
    component: LayoutBlock,
    meta: { title: "测试" },
    redirect: "/app/CS/Cs1",
    children: [
      {
        path: "Cs1",
        name: "Cs1",
        component: () =>
          import(/* webpackChunkName: "controlledfiles" */ "./Cs1/Cs1.vue"),
        meta: { title: "测试网页" },
      },
    ],
  },
];

export default CsRouting;
