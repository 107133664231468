<template>
    <a-config-provider :locale="zhCN">
        <a-spin :spinning="spinning">
            <div class="modal-header">
                <div class="modal-title">
                    <div>续签人数，共{{ this.jobNumber.length }}人</div>
                </div>
            </div>
            <a-row>
                <a-col :span="8" style="text-align: right; line-height: 30px">
                    <span>{{ l("PcIsNotHaveFixedTerm") }}:</span></a-col
                >
                <a-col :span="16">
                    <a-select
                        style="width: 100%; margin-bottom: 10px"
                        :placeholder="l('PleaseSelect')"
                        v-model="Code"
                        @change="RenewNumberCode"
                    >
                        <a-select-option
                            v-for="(item, key) in dicYesOrNo"
                            :value="item.itemDetailCode"
                            :key="key"
                            :title="item.itemDetailName"
                        >
                            {{ item.itemDetailName }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="8" style="line-height: 30px; text-align: right">
                    <span>续签年数:</span></a-col
                >
                <a-col v-if="Code == 'YesOrNo_1'" :span="16">
                    <a-input-number
                        :formatter="(value) => `${value}年`"
                        :default-value="0.1"
                        :min="0.1"
                        :max="100"
                        :step="0.1"
                        style="width: 100%; margin-bottom: 10px"
                        v-model="RenewNumber"
                    />
                    <!-- 小数属性 -->
                    <!-- :step="0.5" -->
                </a-col>
                <a-col
                    v-else
                    style="margin-bottom: 10px"
                    :span="16"
                    class="text-right"
                >
                    <a-input v-model="RenewNumber" :disabled="true"> </a-input>
                </a-col>
                <!-- 按钮 -->
            </a-row>
            <a-row>
                <a-col :span="8" style="line-height: 30px" class="text-right">
                    <span style="color: red">*</span>
                    {{ l("PcType") }}：
                </a-col>
                <a-col :span="16">
                    <a-select
                        style="width: 100%"
                        allowClear
                        v-model="RFCode"
                        option-filter-prop="children"
                        :placeholder="l('PleaseSelect')"
                    >
                        <a-select-option
                            v-for="(item, key) in dicPcContractsType"
                            :value="item.itemDetailName"
                            :key="key"
                            :title="item.itemDetailName"
                        >
                            {{ item.itemDetailName }}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <div class="modal-footer">
                <a-button @click="close">
                    <a-icon type="close-circle" />
                    取消
                </a-button>
                <a-button :type="'primary'" @click="save">
                    <a-icon type="save" />
                    发起续签
                </a-button>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { ContractsManageServiceServiceProxy } from "@/shared";
import Dic from "@/shared/utils/dic";
export default {
    name: "confirm-years",
    mixins: [ModalComponentBase],
    props: {},
    data() {
        return {
            zhCN,
            spinning: false,
            EndDate: undefined,
            RenewNumber: 0.5,
            jobNumber: [],
            dicYesOrNo: [],
            //合同类型字典列表
            dicPcContractsType: [],
            Code: undefined,
            RFCode: undefined,
            Bool: [],
        };
    },
    async created() {
        this.fullData();
        this._contractsManageServiceServiceProxy =
            new ContractsManageServiceServiceProxy(this.$apiUrl, this.$api);
        //初始化字典
        //合同类型
        this.dicPcContractsType = await Dic.getInstance().getDicAsync(
            "PcContractsType"
        );
        this.dicYesOrNo = await Dic.getInstance().getDicAsync("YesOrNo");
        this.Code = "YesOrNo_1";
    },
    methods: {
        save() {
            this._contractsManageServiceServiceProxy
                .getContractRenewal(
                    this.jobNumber,
                    this.RenewNumber,
                    this.RFCode
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.Bool = res;
                    var Success = 0;
                    var failed = 0;
                    var failedText = "";
                    this.Bool.forEach((element) => {
                        if (element.result) {
                            Success = Success + 1;
                        } else {
                            failed = failed + 1;
                            failedText += element.jobNumber + ",";
                        }
                    });
                    if (failed > 0) {
                        this.$notification["success"]({
                            message:
                                Success +
                                "人成功," +
                                failed +
                                "人失败！！" +
                                failedText +
                                "发起失败",
                        });
                    } else {
                        this.$notification["success"]({
                            message: Success + "人成功,",
                        });
                    }

                    this.close();
                });
        },
        RenewNumberCode() {
            if (this.Code == "YesOrNo_2") {
                this.RenewNumber = "无固定期限";
            } else {
                this.RenewNumber = 1;
            }
        },
    },
};
</script>

<style scoped>
.CheckInUser {
    border: 1px solid #d9d9d9;
    min-height: 35px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    line-height: 35px;
    overflow-x: auto;
    cursor: pointer;
}
</style>
