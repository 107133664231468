import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const OARouting: RouteConfig[] = [
    {
        path: 'oa',
        meta: { title: 'oa办公' },
        component: LayoutBlock,
        redirect: '/app/oa/news',
        children: [
            {
                name: 'news',
                path: 'news',
                component: () => import(/* webpackChunkName: "admin" */ './news/news.vue'),
                meta: { title: '312' },
            },
            {
                name: 'notice',
                path: 'notice',
                component: () => import(/* webpackChunkName: "admin" */ './notice/notice.vue'),
                meta: { title: '311' },
            },
            {
                name: 'Sign',
                path: 'Sign',
                component: () => import(/* webpackChunkName: "admin" */ './Sign/Index.vue'),
                meta: { title: '签名' },
            }
        ],
    },
];
export default OARouting;
