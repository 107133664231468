import {RawLocation, Route, VueRouter} from "vue-router/types/router";
import NProgress from "nprogress"; // progress bar
import {abpService, appAuthService, appSessionService} from "@/shared/abp";
import {AppMenus} from "@/abpPro/AppMenus";
import {AppConsts} from "@/abpPro/AppConsts";
import reuseTabService from "../common/reuse-tab/reuse-tab.service";
import lang from '@/shared/utils/lang'


const version = require('@/shared/utils/version.js')

// 默认的登录页和主页
const defaultLoginUrl = "/account/login";
const defaultSetPasswordUrl = "/account/setPassword";
// const resetPasswordUrl="/account/reset-password"

const defaultMainUrl = "/app";

/**
 * 路由守卫
 */
class AppRouteGuard {
  private _inited = false;

  private _loginUrl = defaultLoginUrl;
  private _mainUrl = defaultMainUrl;
  private _whiteList = [defaultLoginUrl, defaultSetPasswordUrl];

  /**
   * 不需要校验权限的路由
   */
  get whiteList(): string[] {
    return this._whiteList;
  }

  set whiteList(val: string[]) {
    if (Array.isArray(val)) {
      this._whiteList = val;
    } else {
      this._whiteList = [];
    }
  }

  /**
   * 登陆页面地址
   */
  get loginUrl(): string {
    return this._loginUrl;
  }

  set loginUrl(val: string) {
    this._loginUrl = val ? val : defaultLoginUrl;
  }

  /**
   * 首页地址
   */
  get mainUrl(): string {
    return this._mainUrl;
  }

  set mainUrl(val: string) {
    this._mainUrl = val ? val : defaultMainUrl;
  }


  /**
   * 初始化路由守卫
   */
  init(rootRooting: VueRouter) {
    if (this._inited) {
      return;
    }


    this._inited = true;
    NProgress.configure({showSpinner: false});

    /** 绑定路由守卫 */
    rootRooting.beforeResolve((to: Route, from: Route, next: any) => {
      this.beforeResolve(to, from, next);
    });

    rootRooting.beforeEach((to: Route, from: Route, next: any) => {
      this.beforeEach(to, from, next);
    });


    const RefreshPageAlertMessage = "您正在浏览的站点已发布更新，为保证正常使用，系统将为您自动刷新页面，请确认您没有正在进行中的重要工作，否则页面数据将会丢失。";

    rootRooting.afterEach((to: Route, from: Route) => {
      this.afterEach(to, from);
      // 如果不想每个路由都检查是否有新版本，只需要在特定的页面才需要检查版本，可以在这里做白名单判断
      // 兼容版本，如果是新版本则进行刷新并缓存
      // version.getPro(false, false).then(res => {
      //   if (res.new) {
      //     if (window.confirm(RefreshPageAlertMessage)) {
      //       version.getPro(true, true)
      //     } else {
      //       version.save(0)
      //     }
      //   }
      // })
    });


    rootRooting.onError((error) => {
      const jsPattern = /Loading chunk (\S)+ failed/g
      const cssPattern = /Loading CSS chunk (\S)+ failed/g
      const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
      const targetPath = rootRooting.currentRoute.fullPath
      if (isChunkLoadFailed) {
        localStorage.setItem('targetPath', targetPath)
        window.location.reload()
      }
    })

    rootRooting.onReady(() => {
   const targetPath = localStorage.getItem('targetPath')
   const tryReload = localStorage.getItem('tryReload')
   if (targetPath) {
     localStorage.removeItem('targetPath')
     if (!tryReload) {
       rootRooting.replace(targetPath)
       localStorage.setItem('tryReload', 'true')
     } else {
       localStorage.removeItem('tryReload')
     }
   }
 })

  }

  /**
   * 路由进入跳转之前
   * @param to
   * @param from
   * @param next
   */
  beforeResolve(to: Route, from: Route, next: any) {
    if (to.path == "/mobilepage") {
      next();
      return;
    }
    if (to.path === "/") {
      next({ path: appRouteGuard.mainUrl });
      return;
    }
    if (to.path === defaultSetPasswordUrl) {
      next();
      return;
    }
    this.checkErrpath(to, next);
    this.setModuleId(to);
    NProgress.start();
    if (appRouteGuard.whiteList.findIndex((o) => o.startsWith(to.path))) {
      if (appRouteGuard.canActivate(to, from, next)) {
        //更改网页title
        if (abpService.abp.userInfo.tenantName) {
          document.title =
            abpService.abp.userInfo.tenantName +
            "-" +
            lang(to.meta.title)   +
            "-企业帮";
        } else {
          document.title = "企业帮-" + to.meta.title;
        }
        let redirect = decodeURIComponent(
          (from.query["redirect"] as string) || to.path
        );

        if (to.path !== redirect) {
          // 跳转到重定向的路由
          next({ path: redirect });
          return;
        }
      }
    }

    next();
  }
  /*
   **写入模块Id(cookie)
   */
  setModuleId(to) {
    //获取最大父级菜单
    let parentItem = reuseTabService.getMaxParantMenus(to.path);
    if (parentItem) {
      //获取当前项
      reuseTabService.getChildren(parentItem.children, to.path);
      abpService.setModuleKey(reuseTabService.tabKey);
    }
  }
  /**
   * 拦截恶意url地址跳转
   * @param to
   * @param next
   */
  checkErrpath(to: Route, next: any) {
    let side = abpService.abp.session.multiTenancySide.toString();
    //只针对租户恶意跳转宿主菜单
    if (side == "Tenant" && abpService.abp.session.userId) {
      let a = AppMenus.HostMenus.filter(
        (item) =>
          item.link == to.path &&
          item.i18n != "Dashboard" &&
          item.public != true
      );
      if (a.length > 0) {
        //清空浏览器缓存，回退到登录页面
        appAuthService.logout();
        // next({ path: appRouteGuard.loginUrl });
        // return;
      }
    }
  }
  /**
   * 路由跳转之前
   * @param to
   * @param from
   * @param next
   */
  beforeEach(to: Route, from: Route, next: any) {
  
    if(to.query.name)
    {
to.meta.title=to.query.name;
    }
    next();
  }

  /**
   * 路由跳转后
   * @param to
   * @param from
   */
  afterEach(to: Route, from: Route) {
    NProgress.done();
  }

  /**
   * 判断是否可访问这个地址
   * @param to 目标地址
   * @param from 现地址
   * @param next
   */
  private canActivate(
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ): boolean {
    if (!appSessionService.user) {
      next({ path: this.loginUrl, query: { redirect: to.fullPath } });
      return false;
    }
    //抛弃原有的权限
    // if (!to.meta || !to.meta['permission']) {
    //     return true;
    // }
    //
    // if (abpService.abp.auth.isGranted(to.meta['permission'])) {
    //     return true;
    // }
    return true;

    // next({ path: this.selectBestRoute(), query: { redirect: to.fullPath } });
    //
    // return false;
  }

  /**
   * 选择最佳的路由地址
   */
  private selectBestRoute(): string {
    if (!appSessionService.user) {
      return this.loginUrl;
    }

    return this.mainUrl;
  }
}

const appRouteGuard = new AppRouteGuard();
export default appRouteGuard;
