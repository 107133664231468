import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const AppSystemAdminIstrationRouting: RouteConfig[] = [
  {
    path: "appsystemadministration",
    meta: { title: "菜单&系统管理" },
    component: LayoutBlock,
    redirect: "/app/appsystemadministration/host-module",
    children: [
      {
        name: "host-module",
        path: "host-module",
        component: () => import("./host-module/host-module.vue"),
        meta: { title: "376" },
      },
    ],
  },
];
export default AppSystemAdminIstrationRouting;
