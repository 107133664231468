import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const controlledfilesRouting: RouteConfig[] = [
  {
    path: "controlledfiles",
    component: LayoutBlock,
    meta: { title: "受控文件" },
    redirect: "/app/controlledfiles/document-cate",
    children: [
      {
        path: "document-cate",
        name: "document-cate",
        component: () =>
          import(
            /* webpackChunkName: "controlledfiles" */ "./document-cate/document-cate.vue"
          ),
        meta: { title: "274" },
      },
      {
        name: "document-file",
        path: "document-file",
        component: () => import("./document-file/document-file.vue"),
        meta: { title: "305" },
      },
      {
        name: "material",
        path: "material",
        component: () => import("./material/material.vue"),
        meta: { title: "372" },
      },
    ],
  },
];

export default controlledfilesRouting;
