import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';

const DatabaseManageRouting: RouteConfig[] = [
    {
        path: 'database-manage',
        meta: { title: '数据库管理' },
        component: LayoutBlock,
        redirect: '/app/database-manage/database',
        children: [
            {
                name: 'database',
                path: 'database',
                component: () => import("./database/database.vue"),
                meta: { title: '283' },
            },
            {
                name: 'function-field',
                path: 'function-field',
                component: () => import("./function-field/function-field.vue"),
                meta: { title: '282' },
            },
            {
                name: 'database-tablefield',
                path: 'database-tablefield',
                component: () => import("./database-tablefield/database-tablefield.vue"),
                meta: { title: '671' },
            }
        ],
    },
];
export default DatabaseManageRouting;
