<template>
	<div class="ant-layout-sider-children">
		<!-- logo -->
		<div class="alain-pro__sider-logo">
			<router-link class="alain-pro__header-logo-link" to="/" v-if="loading">
				<img :src="logoPic" class="tenant-logo" />
			</router-link>
		</div>

		<!-- sidebar -->
		<yoyo-sidebar-nav></yoyo-sidebar-nav>
		<div class="vercss">
			<a @click="Copy(verMessge)" href="#"><a-icon type="fire" /> {{verMessge}}</a>
		</div>
	</div>
</template>

<script>
import { YoyoSidebarNav } from './components';
import bus from '@/shared/bus/bus.js';
import { CompanyImagesServiceProxy, layoutService } from '@/shared';
import { AppConsts } from '@/abpPro/AppConsts';
import apiHttpClient from '@/shared/utils/api-http-client';

export default {
	name: 'sidebar',
	components: {
		YoyoSidebarNav,
	},
	data() {
		return {
			//后台拼接的图片路径
			path: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/`,
			logoPic: '',
			imgData: {},
			loading: false,
			verMessge: AppConsts.BUILDVERSION,
		};
	},
	created() {
		this.companyImgApi = new CompanyImagesServiceProxy(AppConsts.remoteServiceBaseUrl, apiHttpClient);
		this.loading = false;
		this.companyImgApi
			.getCompanyImagesListByTenantId()
			.finally(() => {
				this.loading = true;
			})
			.then((res) => {
				this.imgData = res;
				this.loading = true;
				this.$nextTick(() => {
					var d = new Date();
					if (!layoutService.data.collapsed) {
						//this.logoPic = `OblongLogo/${abp.session.tenantId}/${res.oblongLogo}`;
						this.logoPic=AppConsts.OSSRequestUrl+'/QYB/OblongLogo/'+abp.session.tenantId+'/'+ res.oblongLogo+"?ts="+d.getTime(); 
					} else {
						//this.logoPic = `SquareLogo/${abp.session.tenantId}/${res.squareLogo}`;
						this.logoPic=AppConsts.OSSRequestUrl+'/QYB/SquareLogo/'+abp.session.tenantId+'/'+ res.squareLogo+"?ts="+d.getTime(); 
					}
				});
			});
		bus.$on('changePics', (isClose) => {
			var d = new Date();
			if (!isClose) {
				//this.logoPic = `OblongLogo/${abp.session.tenantId}/${this.imgData.oblongLogo}`;
				this.logoPic=AppConsts.OSSRequestUrl+'/QYB/OblongLogo/'+abp.session.tenantId+'/'+ this.imgData.oblongLogo+"?ts="+d.getTime(); 
			} else {
				//this.logoPic = `SquareLogo/${abp.session.tenantId}/${this.imgData.squareLogo}`;
				this.logoPic=AppConsts.OSSRequestUrl+'/QYB/SquareLogo/'+abp.session.tenantId+'/'+ this.imgData.squareLogo+"?ts="+d.getTime(); 
			}
		});
	},
	methods:{
		Copy(text) {
			this.$copyText(text).then((e) => {
				this.$message.success("复制成功！");
			});
		},
	}
};
</script>

<style lang="less" scoped>
@import './sidebar.less';
.alain-pro__sider-logo {
	padding-left: 0px;
	height: 64px;
	background: white;
}
.tenant-logo {
	height: 50px;
}
.vercss{
	height: 25px; 
	line-height: 20px; 
	width: 100%; 
	text-align: center;
	bottom: 0; 
	position: absolute; 
	font-size: 12px;
	overflow: hidden;
	white-space: nowrap; 
	text-overflow: ellipsis;
	background: #263458;
	box-shadow: 1px 1px 8px 3px #184aa7;
	a{
		color: #575a66;
	}
}
</style>
