import { render, staticRenderFns } from "./nav-item-icon.vue?vue&type=template&id=73b415ba&scoped=true&functional=true&"
import script from "./nav-item-icon.vue?vue&type=script&lang=js&"
export * from "./nav-item-icon.vue?vue&type=script&lang=js&"
import style0 from "./nav-item-icon.vue?vue&type=style&index=0&id=73b415ba&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "73b415ba",
  null
  
)

export default component.exports