import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const SuggestionBoxRouting: RouteConfig[] = [
  {
    path: "suggestionbox",
    meta: { title: "系统意见箱" },
    component: LayoutBlock,
    redirect: "/app/suggestionbox/suggestionbox-list",
    children: [
      {
        name: "Feedback",
        path: "Feedback",
        component: () => import("./feedbackrecord/Feedback.vue"),
        meta: { title: "672" },
      },
      {
        name: "suggestionbox-list",
        path: "suggestionbox-list",
        component: () => import("./suggestionbox-list/suggestionbox-list.vue"),
        meta: { title: "381" },
      },
      {
        name: "formtype",
        path: "formtype",
        component: () => import("./formtype/formtype.vue"),
        meta: { title: "384" },
      },
      {
        name: "design-page",
        path: "design-page",
        component: () => import("./design-page/design-page.vue"),
        meta: { title: "设计表单" },
      },
      {
        name: "mobilepage",
        path: "mobilepage",
        component: () => import("./mobilepage/mobilepage.vue"),
        meta: { title: "表单" },
      },
      // {
      //   name: "opinionsrecord",
      //   path: "opinionsrecord",
      //   component: () => import("./opinionsrecord/opinionsrecord.vue"),
      //   meta: { title: "" },
      // },
      {
        name: "feedbackrecord",
        path: "feedbackrecord",
        component: () => import("./feedbackrecord/feedbackrecord.vue"),
        meta: { title: "383" },
      },
      {
        name: "design-page-yijian",
        path: "design-page-yijian",
        component: () => import("./design-page-yijian/design-page-yijian.vue"),
        meta: { title: "反馈表单编辑" },
      },
      {
        name: "desgin-page-interview",
        path: "desgin-page-interview",
        component: () => import("./desgin-page-interview/desgin-page-interview.vue"),
        meta: { title: "应聘登记表单编辑" },
      },
      {
        name: "formlist-detail",
        path: "formlist-detail",
        component: () => import("./suggestionbox-list/formlist-detail/formlist-detail.vue"),
        meta: { title: "提交记录" },
      },
      {
        name: "MyToDo",
        path: "MyToDo",
        component: () => import("./MyToDo/MyToDo.vue"),
        meta: { title: "我的待办" },
      },
      {
        name: "design-page-score",
        path: "design-page-score",
        component: () => import("./design-page-score/design-page-score.vue"),
        meta: { title: "设计增肌得分" },
      }
    ],
  },
];
export default SuggestionBoxRouting;
