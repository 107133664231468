


























































































































































































































































































































import { ModalComponentBase } from "@/shared/component-base";
import Dic from "@/shared/utils/dic";
import {
    CreateOrUpdatePersonnelContractsInput,
    PersonnelContractsEditDto,
    PersonnelContractsServiceProxy,
} from "@/shared/service-proxies";
import moment from "moment";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
    name: "create-or-edit-personnel-contracts",
    mixins: [ModalComponentBase],
    data() {
        return {
            _personnelContractsServiceProxy: null,
            zh_CN,
            //上个页面的数据
            //合同ID
            id: "",
            userId: "",
            //表单合同实体
            pcDto: new PersonnelContractsEditDto(),

            //合同类型字典列表
            dicPcContractsType: [],
            //签订方式
            dicPcSignedMode: [],
            //是否固定期  是否完成
            dicYesOrNo: [],
            pcDateIsRequire: false,
        };
    },
    async created() {
        this.fullData();
        this._personnelContractsServiceProxy =
            new PersonnelContractsServiceProxy(this.$apiUrl, this.$api);
        try {
            this.loading = true;
            //初始化字典
            //合同类型
            this.dicPcContractsType = await Dic.getInstance().getDicAsync(
                "PcContractsType"
            );
            //签订方式
            this.dicPcSignedMode = await Dic.getInstance().getDicAsync(
                "PcSignedMode"
            );
            //是否固定期  是否完成
            this.dicYesOrNo = await Dic.getInstance().getDicAsync("YesOrNo");
            //获取后台实体数据
            this.pcDto = await this.getForEdit();
        } catch (e) {
            console.error(e);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        /**
         * 获取编辑数据
         */
        getForEdit() {
            return new Promise((resolve, reject) => {
                this._personnelContractsServiceProxy
                    .getForEdit(this.id)
                    .then((res) => {
                        resolve(res.personnelContracts);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        /**
         * 提交表单
         */
        async handleSubmit() {
            if (await this.checkSubmit()) {
                let input = new CreateOrUpdatePersonnelContractsInput();
                this.saving = true;
                this.loading = true;
                this.pcDto.empId = this.userId;
                input.personnelContracts = this.pcDto;
                this._personnelContractsServiceProxy
                    .createOrUpdate(input)
                    .finally(() => {
                        this.saving = false;
                        this.loading = false;
                    })
                    .then(() => {
                        this.$notification.success({
                            description: undefined,
                            message: this.l("SavedSuccessfully"),
                        });
                        this.success(true);
                    });
            }
        },
        /**
         * 检查表单
         */
        checkSubmit() {
            return new Promise((resolve, reject) => {
                if (!this.pcDto.pcNumber) {
                    abp.message.warn("请输入合同编号");
                    reject(false);
                } else if (!this.pcDto.pcTypeCode) {
                    abp.message.warn("请选择合同类型");
                    reject(false);
                } else if (!this.pcDto.signedModeCode) {
                    abp.message.warn("请选择签订方式");
                    reject(false);
                } else if (!this.pcDto.isCompleteCode) {
                    abp.message.warn("请选择是否完成");
                    reject(false);
                } else {
                    if (this.pcDto.isNotHaveFixedTermCode === undefined) {
                        abp.message.warn("请选择固定期");
                        reject(false);
                    } else {
                        if (this.pcDateIsRequire) {
                            if (!this.pcDto.startDate) {
                                abp.message.warn("请选择合同开始日期");
                                reject(false);
                            } else if (!this.pcDto.endDate) {
                                abp.message.warn("请选择合同结束日期");
                                reject(false);
                            }
                        }
                    }
                }
                resolve(true);
            });
        },
        /**
         * 是否固定期，是否完成改变
         * @param e 是否
         * @param type 是否是固定期 isNotHaveFixedTermId
         */
        async onYesOrNoChange(e, type) {
            if (type === "isNotHaveFixedTermId") {
                this.pcDateIsRequire = await Dic.getInstance().getYesOrNoBool(
                    this.dicYesOrNo,
                    e
                );
                if (!this.pcDateIsRequire) {
                    // this.pcDto.startDate = undefined;
                    this.pcDto.endDate = undefined;
                }
            }
        },
        /**
         *  合同开始结束日期改变，并计算合同期限
         * @param e 值
         */
        onPcDateChange(e) {
            if (this.pcDto.startDate && this.pcDto.endDate) {
                if (this.pcDto.endDate < this.pcDto.startDate) {
                    this.pcDto.endDate = undefined;
                    abp.message.warn("结束日期不能小于开始日期");
                } else {
                    //this.pcDto.endDate.diff(this.pcDto.startDate, 'years', true).toFixed(2);

                    this.pcDto.dueTerm = this.pcDto.endDate
                        .diff(this.pcDto.startDate, "years", true)
                        .toFixed(2);
                }
            }
        },
    },
};
