import {environment} from "@/environments/environment";
import {preloaderFinished} from "@/shared/utils";
import {BrowserTracing} from "@sentry/tracing";


import * as Sentry from "@sentry/vue";
import VForm from "@sz-xinxu/qyb-vform-pro/dist/lib/VFormDesigner.common.js"; //引入VForm库
import "@sz-xinxu/qyb-vform-pro/dist/lib/VFormDesigner.css"; //引入VForm样式
import Antd from "ant-design-vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import JsonPath from 'jsonpath';
import moment from "moment";
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import Vue from "vue";
import AMap from "vue-amap";
import VueClipboard from 'vue-clipboard2';
import VueLazyload from "vue-lazyload";
import Print from 'vue-print-nb';

import QuillEditor from 'vue-quill-editor';
import htmlPDF from '../src/app/recruit/Js/htmlToPdf.js';
import {AppPreBootstrap} from "./AppPreBootstrap"; // 预启动器
import {setupComponents} from "./components";
import root from "./root.vue"; // 主容器组件
import {rootRouting} from "./router"; // 全局路由
import {appSessionService, permissionService} from "./shared/abp";
import {setupIcon} from "./shared/antd/import-antd-icon";
import {authRouteGuard} from "./shared/auth"; // 路由守卫
import {layoutService, menuService, reuseTabService} from "./shared/common";
import {UserNotificationHelper} from "./shared/helpers";
import {localizationService} from "./shared/i18n";
import {rootStore} from "./shared/store"; // 全局store
import "./styles/index.less"; // 全局样式注册
Vue.prototype.$JsonPath = JsonPath;

import {ExtraErrorData} from '@sentry/integrations';

import vant from 'vant'
import 'vant/lib/index.css';
import '@vant/touch-emulator';

Vue.use(vant)
Vue.use(Print);
Vue.use(htmlPDF);
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(QuillEditor);
Vue.use(VForm);
Vue.use(Antd);
Vue.use(VueLazyload, {
  //开启默认事件
  dispatchEvent: true,
});
Vue.prototype.$bus = new Vue();
Vue.use(AMap);
// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: "e71901894dd4d6ec5b58aab53e29ee60",
  // 插件集合 （插件按需引入）
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: "1.0",
});

function lt(key, ...args) {
  return localizationService.l(key, ...args);
}
Vue.prototype.$l = lt;




/**
 * 过滤器
 */
Vue.filter("moment", (value, formatString) => {
  formatString = formatString || "YYYY-MM-DDTHH:mm:ss";
  return moment(value).format(formatString);
});
/**
 * 重写moment的toISOString(),解决接口参数序列化后时间少8小时的问题
 */
moment.prototype.toISOString = function () {
  return moment(this).format("YYYY-MM-DD HH:mm:ss");
};



AppPreBootstrap.run(() => {

  // 初始化会话信息
  appSessionService.init().then((res) => {


    // if (environment.production) {
    //   InitSentry(rootRouting);
    // }

    setupIcon();
    setupComponents();


    // 初始化路由守卫
    authRouteGuard.init(rootRouting);
    // 初始化复用标签
    reuseTabService.init(rootRouting, localizationService);
    // 初始化菜单服务
    menuService.initService(localizationService, permissionService);

    // 初始化通知服务
    UserNotificationHelper.init();

    // 启用复用标签
    layoutService.data.reuseTab = true;
    // 初始化菜单
    // menuService.add(AppMenus.Menus);

    new Vue({
      router: rootRouting,
      store: rootStore,
      render: (h) => h(root),
      mounted() {
        preloaderFinished();
      },
    }).$mount("#app");
  });
});



/**
 * 初始化Sentry监控
 * @param rootRouting
 * @constructor
 */
function InitSentry(rootRouting) {
  try {
    Sentry.init({
      Vue,
      dsn: "https://ae62c6c313044ddc9633ffd75f48b0c2@qyb.szjunchuang.com:8788/sentryapp/5",
      replaysSessionSampleRate: 1,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(rootRouting),
        }),
        // new Sentry.Replay({
        //   maskAllText: false,
        //   maskAllInputs: false,
        //   blockAllMedia:true,
        //   useCompression:true,
        //   ignore:[".el-container",".render-form"]
        // }), //render-form  //el-container
        new ExtraErrorData({depth: 4})
      ],
      // // Set tracesSampleRate to 1.0 to capture 100%
      // // of transactions for performance monitoring.
      // // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      denyUrls:["https://qyb.szjunchuang.com:8788/sentryapp/"],
      autoSessionTracking:false,
      normalizeDepth:10,
      normalizeMaxBreadth:100
    });
  } catch (e) {
    console.warn("前端监控初始化失败");
  }
}
