import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const costcenterrouting: RouteConfig[] = [
  {
    path: "costcenter",
    meta: { title: "成本中心" },
    component: LayoutBlock,
    redirect: "/app/costcenter/costcenter-page",
    children: [
      {
        name: "costcenter-page",
        path: "costcenter-page",
        component: () => import("./costcenter-page/costcenter-page.vue"),
        meta: { title: "costcenter-page" },
      },
    ],
  },
];
export default costcenterrouting;