<template>
	<q-container usePagination :loading="loading">
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="6">
					<b v-if="Year">{{ Year }}年</b><b v-if="Month">{{ Month }}月</b>过生日的员工(<b>{{ totalItems }}</b
					>人)
				</a-col>
				<a-col :span="4">
					<a-tree-select
						@change="companyChange"
						allowClear
						:placeholder="l('Company')"
						style="width: 100%"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						v-model="DepartmentId"
						style="width: 100%"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						allow-clear
						@change="SelectDepartmentTree"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-month-picker
						placeholder="选择日期"
						:allowClear="false"
						@change="onDateChange"
						v-model="TimeData"
					/>
				</a-col>
				<a-col :span="3">
					<a-button :type="'primary'" @click="createOrEditWish" v-if="isGranted('SettingBirthday')"
						>设置生日祝福
					</a-button>
				</a-col>
			</a-row>
		</template>
		<template slot="table" slot-scope="scopeParams">
			<a-table
				@change="sorterChange"
				size="small"
				:scroll="{ y: scopeParams.scroll.y }"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:dataSource="tableData"
			>
			</a-table>
		</template>
		<template #page>
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { CompanyServiceProxy, DepartmentServiceProxy, UserServiceProxy } from '../../../../shared/service-proxies';
import { AppComponentBase } from '../../../../shared/component-base';
import moment from 'moment';
import { ModalHelper } from '@/shared/helpers';
import CreateOrEditWish from '../create-or-edit-wish/create-or-edit-wish';
import abpService from '@/shared/abp/abp.service';
import DepTreeUntils from '@/app/organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'birthday-wish',
	mixins: [AppComponentBase],
	data() {
		return {
			columns: [
				{
					title: this.l('姓名'),
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('339'),
					dataIndex: 'depantment',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'depantment' },
				},
				{
					title: this.l('岗位'),
					dataIndex: 'post',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'post' },
				},
				{
					title: this.l('员工类型'),
					dataIndex: 'staffTypeName',
					sorter: true,
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'staffTypeName' },
				},
				{
					title: this.l('生日'),
					sorter: true,
					dataIndex: 'birthday',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'birthday' },
				},
			],
			tableData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			Year: undefined,
			Month: undefined,
			companyTreeList: [],
			DepTreeData: [],
			yearIsopen: false,
			monthIsopen: false,
			TimeData: undefined,
		};
	},
	created() {
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		// this.columns=this.getColumn("生日祝福",this.columns);
	},
	mounted() {
		let date = moment();
		this.Year = moment(date).format('YYYY');
		this.Month = moment(date).format('MM');
		this.TimeData = moment(date).format('YYYY-MM');
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.companyChange(this.CompanyId);
		}
		this.getData();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		getData() {
			this.loading = true;
			this._UserServiceProxy
				.getBirthdayWishList(
					this.CompanyId ? this.CompanyId : undefined,
					this.DepartmentId ? this.DepartmentId : undefined,
					this.Year ? this.Year : '',
					this.Month ? this.Month : '',
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.birthday = item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.getData();
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.companyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			if (!this.CompanyId) {
				this.DepartmentId = undefined;
				this.DepTreeData = [];
			}
			this.getData();
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepartmentId = undefined;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.DepartmentId = e;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},

		onDateChange(v) {
			this.Month = v ? moment(v).format('MM') : undefined;
			this.Year = v ? moment(v).format('YYYY') : undefined;
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},
		createOrEditWish() {
			ModalHelper.create(
				CreateOrEditWish,
				{
					type: 0,
				},
				{
					width: '700px',
				}
			).subscribe((res) => {
				if (res) {
				}
			});
		},
	},
};
</script>

<style scoped></style>
