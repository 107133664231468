import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const externalapprouting: RouteConfig[] = [
  {
    path: "externalapp",
    meta: { title: "菜单&系统管理" },
    component: LayoutBlock,
    redirect: "/app/externalapp/externalapp-page",
    children: [
      {
        name: "externalapp-page",
        path: "externalapp-page",
        component: () => import("./externalapp-page/externalapp-page.vue"),
        meta: { title: "externalapp-page" },
      },
    ],
  },
];
export default externalapprouting;