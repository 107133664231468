import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";
import abpService from "@/shared/abp/abp.service";

const adminRouting: RouteConfig[] = [
  {
    path: "admin",
    meta: { title: "系统" },
    component: LayoutBlock,
    redirect: "/app/admin/user",
    children: [
      // { path: 'users', component: () => import(/* webpackChunkName: "admin" */ './users/users.vue'),meta: {title:  "users" } },
      {
        name: "roles",
        path: "roles",
        component: () =>
          import(/* webpackChunkName: "admin" */ "./roles/roles.vue"),
        meta: { title: "308" },
      },
      {
        name: "auditLogs",
        path: "auditLogs",
        component: () =>
          import(/* webpackChunkName: "admin" */ "./audit-logs/audit-logs.vue"),
        meta: { title: "审计日志" },
      },
      {
        name: "lrlog",
        path: "lrlog",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./lr_base_log/lr_base_log.vue"
          ),
        meta: { title: "审批流日志" },
      },
      {
        name: "file-manager",
        path: "file-manager",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./file-manager/file-manager.vue"
          ),
        meta: { title: "文件管理" },
      },
      {
        name: "maintenance",
        path: "maintenance",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./maintenance/maintenance.vue"
          ),
        meta: { title: "Menu.Web.host-4" },
      },
      {
        name: "host-settings",
        path: "host-settings",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./host-settings/host-settings.vue"
          ),
        meta: { title: "Menu.Web.host-5" },
      },
      {
        name: "editions",
        path: "editions",
        component: () =>
          import(/* webpackChunkName: "admin" */ "./editions/editions.vue"),
        meta: { title: "日志" },
      },
      {
        path: "languages",
        component: () =>
          import(/* webpackChunkName: "admin" */ "./languages/languages.vue"),
      },
      {
        name: "languagetexts",
        path: "languagetexts",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./language-texts/language-texts.vue"
          ),
      },
      {
        name: "tenants",
        path: "tenants",
        component: () =>
          import(/* webpackChunkName: "admin" */ "./tenants/tenants.vue"),
        meta: { title: "Menu.Web.host-3" },
      },

      {
        name: "organization-units",
        path: "organization-units",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./organization-units/organization-units.vue"
          ),
        meta: { title: "240" },
      },
      {
        name: "subscription-management",
        path: "subscription-management",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./subscription-management/subscription-management.vue"
          ),
      },
      {
        name: "tenant-settings",
        path: "tenant-settings",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./tenant-settings/tenant-settings.vue"
          ),
        meta: { title: "设置" },
      },
      {
        name: "strategy-info",
        path: "strategy-info",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "./strategy-info/strategy-info.vue"
          ),
      },
      {
        name: "host-module",
        path: "host-module",
        component: () => import("./host-module/host-module.vue"),
        meta: { title: "375" },
      },
      {
        name: "tenant-info",
        path: "tenant-info",
        component: () => import("./tenant-info/tenant-info.vue"),
        meta: { title: "310" },
      },
      {
        name: "account",
        path: "account",
        component: () => import("./account/account.vue"),
        meta: { title: "260" },
      },
      {
        name: "timed-task",
        path: "timed-task",
        component: () => import("./timed-task/hangfire/hangfire.vue"),
        meta: { title: "259" },
      },
      {
        name: "host-feedback",
        path: "host-feedback",
        component: () => import("./host-feedback/host-feedback.vue"),
        meta: { title: "253" },
      },
      {
        name: "report",
        path: "report",
        component: () => import("./host-feedback/report/report.vue"),
        meta: { title: "248" },
      },
      {
        name: "interface-manager",
        path: "interface-manager",
        component: () => import("./interface-manager/interface-manager.vue"),
        meta: { title: "Menu.Web.host-8" },
      },
      {
        name: "interface-authorize-detail",
        path: "interface-authorize-detail",
        component: () =>
          import("./interface-authorize-detail/interface-authorize-detail.vue"),
        meta: { title: "246" },
      },
      {
        name: "app-update-manage",
        path: "app-update-manage",
        component: () => import("./app-update-manage/app-update-manage.vue"),
        meta: { title: "Menu.Web.host-3-1" },
      },
      {
        name: "multilingual-setting",
        path: "multilingual-setting",
        component: () =>
          import("./multilingual-setting/multilingual-setting.vue"),
        meta: { title: "278" },
      },
      {
        name: "master-moudlezuhu",
        path: "master-moudlezuhu",
        component: () =>
          import("./master-moudlezuhu/host-module/host-module.vue"),
        meta: { title: "Menu.Web.host-11" },
      },
      // {
      //     path: 'hangfire',
      //     component: () => import(/* webpackChunkName: "admin" */ './roles/roles.vue'),
      // }
      // { path: 'company', component: () => import(/* webpackChunkName: "admin" */ '../organization/ompany/company.vue')}
    ],
  },
];

export default adminRouting;
