import { PageHeader } from '@/components/page-header';
import QContainer from '@/components/q-container/q-container.vue';
import QTreeContainer from '@/components/q-container/q-tree-container.vue';
import QLabel from '@/components/q-container/q-label.vue';
import VueDraggableResizable from 'vue-draggable-resizable';

import Vue from 'vue';

export function setupComponents() {
	Vue.component('PageHeader', PageHeader);
	Vue.component('QContainer', QContainer);
	Vue.component('QTreeContainer', QTreeContainer);
	Vue.component('QLabel', QLabel);
	Vue.component('vue-draggable-resizable', VueDraggableResizable);
}

export * from './reuse-tab';
