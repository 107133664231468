<template>
	<div class="ant-layout-header alain-pro__header">
		<!-- 左边 -->
		<div class="d-flex align-items-center">
			<!-- Menu 收缩按钮-->
			<div @click="collapsed = !collapsed" class="alain-pro__header-item alain-pro__header-trigger">
				<a-icon type="menu-fold" v-show="!collapsed" />
				<a-icon type="menu-unfold" v-show="collapsed" />
			</div>
			<div class="d-flex align-items-center scroll">
				<a-menu mode="horizontal" v-model="selectKey">
					<a-menu-item v-for="item in menuList" :key="item.key" @click="menuclick(item)" :title="l('Menu.Web.'+item.key)">
						<div class="menu-item">
							<div class="item-icon" v-if="item.icon">
								<icon-font :type="item.icon" style="font-size:20px;margin-top: 4px;"></icon-font>
							</div>
							<div class="item-title">
								{{l('Menu.Web.'+item.key) }}
							</div>
						</div>
					</a-menu-item>
				</a-menu>
			</div>
		</div>

		<!-- 右边 -->
		<div class="alain-pro__header-right">
			<multilingualComponents />
			<header-notifications class="alain-pro__header-item mr-sm"></header-notifications>
			<header-settings class="alain-pro__header-item hidden-xs"></header-settings>
			<header-user class="hidden-xs alain-pro__header-item"></header-user>
		</div>
	</div>
</template>

<script>
import { HeaderI18n, HeaderNotifications, HeaderSettings, HeaderUser } from './components';
import { layoutService, menuService } from '@/shared/common';
import bus from '@/shared/bus/bus.js';
import { AppMenus } from '@/abpPro/AppMenus';
import abpService from '@/shared/abp/abp.service';
import { rootStore } from '@/shared';
import  multilingualComponents  from "@/components/multilingual-components/multilingual-components.vue"
import  {AppComponentBase}  from "@/shared/component-base";
import {localizationService} from "@/shared/i18n";

export default {
	name: 'layout-header',
	components: {
		HeaderI18n,
		HeaderNotifications,
		HeaderSettings,
		HeaderUser,
		multilingualComponents,
	},
	data() {
		return {
			menuList: [],
			selectKey: [],
		};
	},
	props: {
		l:{
			type:Function,
			default:(str)=>{
				return localizationService.l(str);
			}
		}
	},
	computed: {
		collapsed: {
			get() {
				bus.$emit('changePics', layoutService.data.collapsed);
				return layoutService.data.collapsed;
			},
			set(val) {
				let obj = { ...layoutService.data };
				obj.collapsed = val;
				layoutService.set(obj);
				bus.$emit('changePics', val);
			},
			
		},
	},
	mounted() {
		if (abpService.abp.session.tenantId) {
			this.menuList = AppMenus.HeaderMenus;
			//模拟点击第一个菜单
			if (this.menuList.length > 0) {
				this.menuclick(this.menuList[0]);
			}
		}
		this.selectMenus();
	},
	methods: {
		/**
		 *模拟点击
		 */
		selectMenus() {
			bus.$on('headerMenuClick', (item) => {
				this.selectKey = [];
				this.selectKey = [item.key];
				this.menuclick(item);
			});
		},
		menuclick(item) {
			this.$router.push({ path: item.link });
			if (item.children) {
				
				let menus = item.children;
				menus = [...menus];
				menuService.add(menus);
				console.log();
				console.log(menus,'传');
			}
		},
	},
};
</script>

<style scoped>
.scroll {
	max-width: 1000px;
	overflow-y: auto;
}
.ant-menu-horizontal {
	border: 0px;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
	top: 0px;
}
.scroll::-webkit-scrollbar {
	height: 3px;
}
/* 滚动槽 */
.scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: #808080;
	border-radius: 10px;
}
/* 滚动条滑块 */
.scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: #808080;
}
.scroll::-webkit-scrollbar-thumb:window-inactive {
	background: #808080;
}
.menu-item {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.item-icon {
	flex: 1;
	padding-bottom: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.item-title {
	flex: 2;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
