<template>
	<div class="container2" style="overflow: hidden">
		<div class="top-wrap">
			<div class="top-part">
				<div class="top-logo">
					<img class="left-img1" src="/assets/images/logos/logo.svg" />
					<span>企业帮-企业经营之道</span>
				</div>
				<div class="top-text">
					<span class="span">人员</span><a-divider type="vertical" /> <span class="span">质量</span
					><a-divider type="vertical" /> <span class="span">技术</span><a-divider type="vertical" />
					<span class="span">客户</span><a-divider type="vertical" />
					<span class="span">利润</span>
				</div>
			</div>
		</div>

		<router-view />
		<div class="copyright">
			<p>©{{ currentYear }}苏州骏创软件Ver2.0.1[20210205]</p>
			<p>苏ICP备15059690号-1</p>
		</div>
	</div>
</template>

<script>
import {AppComponentBase} from '@/shared/component-base';
import {appSessionService} from '@/shared';

export default {
  name: 'account-layout',
  mixins: [AppComponentBase],
  data() {
    return {
      links: [
        {
          title: 'ABP',
          href: '',
        },
        {
          title: '隐私',
          href: '',
        },
        {
          title: '条款',
          href: '',
        },
      ],
			loginBg: '',
		};
	},
	computed: {
		currentYear: (vm) => {
			return new Date().getFullYear();
		},
		versionText: (vm) => {
			return appSessionService.application.version;
		},
	},
};
</script>

<style lang="less" scoped>
@import './account-layout.less';
</style>
