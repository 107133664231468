import { RouteConfig } from 'vue-router';
import { LayoutBlock } from '@/layout';
const MessageManageRouting: RouteConfig[] = [
    {
        path: 'message-manage',
        meta: { title: '消息设置' },
        component: LayoutBlock,
        redirect: '/app/message-manage/sysclassify',
        children: [
            {
                name: 'sysclassify',
                path: 'sysclassify',
                component: () => import(/* webpackChunkName: "admin" */ './sysclassify/sysclassify.vue'),
                meta: { title: '273' },
            },
            {
                name: 'jump-protocol',
                path: 'jump-protocol',
                component: () => import(/* webpackChunkName: "admin" */ './jump-protocol/jump-protocol.vue'),
                meta: { title: '303' },
            },
        ],
    },
];

export default MessageManageRouting;
