<template>
    <!-- usePagination开启分页 -->
    <!-- loading绑定引入页面的蒙层加载变量 -->
    <q-container usePagination :loading="loading">
        <!-- 使用具名插槽 #toolbar -->
        <!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
        <template #toolbar>
            <a-row :gutter="[8, 8]">
                <a-col :span="12">
                    <a-button
                        type="primary"
                        @click="exportFormClick"
                        v-if="isGranted('contracts_excel')"
                    >
                        <a-icon type="download" />
                        <span>导出</span>
                    </a-button>

                    近<b>{{ monthData }}</b
                    >个月即将到期和已到期员工 (共<b>{{ totalItems }}</b
                    >人)
                </a-col>

                <a-col :span="12">
                    <div style="text-align: right">
                        <a-tree-select
                            @change="companyChange"
                            multiple
                            :placeholder="l('Company')"
                            style="width: 200px; margin-right: 10px"
                            allow-clear
                            tree-default-expand-all
                            :dropdown-style="{
                                maxHeight: '400px',
                                overflow: 'auto',
                            }"
                            :tree-data="companyTreeList"
                            v-model="CompanyId"
                        />
                        <a-date-picker
                            style="margin-right: 10px"
                            v-model="EndTime"
                            placeholder="结束日期"
                            @change="onDateChange"
                            :allowClear="false"
                        />
                        <a-button
                            type="primary"
                            style="
                                margin-right: 10px;
                                height: 40px;
                                line-height: 40px;
                            "
                            @click="Renew"
                        >
                            <a-icon type="download" />
                            <span>发起续签</span>
                        </a-button>
                    </div>
                </a-col>
            </a-row>
        </template>
        <!-- 使用具名插槽slot="table" -->
        <!-- 插槽作用域的变量 slot-scope="scopeParam" -->
        <!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

        <!-- **************行选择*************** -->
        <!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
        <!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
        <template slot="table" slot-scope="scopeParam">
            <a-table
                ref="table"
                size="small"
                :pagination="false"
                :columns="columns"
                bordered
                :rowKey="(tableDatas) => tableDatas.id"
                :scroll="{ x: 1700, y: scopeParam.scroll.y }"
                :dataSource="tableData"
                :rowSelection="rowSelection"
                @change="sorterChange"
                :customRow="customRow"
            >
                <span slot="actions" slot-scope="text, record">
                    <a @click.stop="Renew(record)">续签 </a>
                </span>
            </a-table>
        </template>
        <!-- 使用具名插槽 #page -->
        <template #page>
            <!-- 分页组件只需复制以下代码 -->
            <!-- 处理逻辑和变量都在 app-component-base混入文件 -->
            <a-pagination
                v-model="pageNumber"
                :total="totalItems"
                showSizeChanger
                size="small"
                :defaultPageSize="request.maxResultCount"
                :pageSizeOptions="pageSizeOptions"
                :showTotal="() => showTotal"
                @change="onPageChange"
                @showSizeChange="showPageSizeChange"
            />
        </template>
    </q-container>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { abpService, ContractsManageServiceServiceProxy } from "@/shared";
import moment from "moment";
import { ModalHelper } from "@/shared";
import CreateOrEditPersonnelContracts from "../../../app/admin/users/view-user/create-or-edit-personnel-contracts/create-or-edit-personnel-contracts";
import { CompanyServiceProxy } from "../../../shared/service-proxies/service-proxies";
import { Dic, fileDownloadService } from "../../../shared/utils";
import ConfirmYear from "./confirm-years/confirm-years.vue";
import { forEach } from "lodash";
export default {
    name: "contracts-manage",
    mixins: [AppComponentBase],
    components: { CreateOrEditPersonnelContracts, ConfirmYear },
    data() {
        return {
            columns: [
                {
                    title: this.l("工号"),
                    dataIndex: "jobNumber",
                    sorter: false,
                    ellipsis: true,
                    align: "center",
                    width: "100px",
                },
                {
                    title: this.l("姓名"),
                    dataIndex: "realName",
                    sorter: false,
                    ellipsis: true,
                    align: "center",
                    width: "100px",
                    scopedSlots: { customRender: "realName" },
                },
                {
                    title: this.l("部门"),
                    dataIndex: "derpanment",
                    sorter: false,
                    ellipsis: true,
                    width: "140px",
                    align: "center",
                    scopedSlots: { customRender: "derpanment" },
                },
                {
                    title: this.l("开始日期"),
                    dataIndex: "startTime",
                    sorter: false,
                    ellipsis: true,
                    width: "140px",
                    align: "center",
                    scopedSlots: { customRender: "startTime" },
                },
                {
                    title: this.l("结束日期"),
                    dataIndex: "endTime",
                    align: "center",
                    width: "140px",
                    ellipsis: true,
                    scopedSlots: { customRender: "endTime" },
                },
                {
                    title: this.l("合同公司"),
                    dataIndex: "company",
                    align: "center",
                    width: "140px",
                    ellipsis: true,
                    scopedSlots: { customRender: "company" },
                },
                {
                    title: this.l("合同状态"),
                    dataIndex: "hT_STATUS",
                    align: "center",
                    width: "140px",
                    ellipsis: true,
                    scopedSlots: { customRender: "hT_STATUS" },
                },
                {
                    title: this.l("合同类型"),
                    dataIndex: "contractsType",
                    align: "center",
                    width: "140px",
                    ellipsis: true,
                    scopedSlots: { customRender: "contractsType" },
                },

                {
                    title: this.l("续签次数"),
                    dataIndex: "renewFrequency",
                    align: "center",
                    width: "140px",
                    scopedSlots: { customRender: "renewFrequency" },
                    ellipsis: true,
                },
                {
                    title: this.l("Actions"),
                    dataIndex: "actions",
                    align: "center",
                    scopedSlots: { customRender: "actions" },
                    width: "80px",
                },
            ],
            tableData: [],
            DateTime: [],
            StartTime: undefined,
            EndTime: undefined,
            monthData: undefined,
            CompanyId: [],
            companyTreeList: [],
            _fileDownloadService: undefined, //导出
            //表格是否多选
            isMultiple: true,
            ispayList: [
                { code: 0, name: "否" },
                { code: 1, name: "是" },
            ],
            jobNumber: [],
        };
    },
    created() {
        this._contractsManageServiceServiceProxy =
            new ContractsManageServiceServiceProxy(this.$apiUrl, this.$api);
        this._CompanyConfigServiceProxy = new CompanyServiceProxy(
            this.$apiUrl,
            this.$api
        );
        this._fileDownloadService = fileDownloadService;
    },
    mounted() {
        this.StartTime = moment();
        this.EndTime = moment().add(2, "months");
        // this.DateTime = [this.StartTime, this.EndTime];
        this.monthData = moment(this.EndTime).diff(
            moment(this.StartTime),
            "months"
        );

        this.companyTreeInit();
        if (abpService.abp.userInfo.companyId) {
            this.CompanyId.push(abpService.abp.userInfo.companyId);
        }
        this.getData();
    },
    methods: {
        getData() {
            this.loading = true;

            console.info(this.CompanyId);
            var conpid = "";
            this.CompanyId.map((n) => {
                conpid += n + ",";
            });

            this._contractsManageServiceServiceProxy
                .getContractsManageList(
                    0,
                    conpid,
                    // this.StartTime,
                    this.EndTime,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.tableData.map((item) => {
                        item.startTime = item.startTime
                            ? moment(item.startTime).format("YYYY-MM-DD")
                            : " ";
                        item.endTime = item.endTime
                            ? moment(item.endTime).format("YYYY-MM-DD")
                            : " ";
                    });
                    this.totalItems = res.totalCount;
                });
        },

        //导出
        exportFormClick() {
            this.loading = true;

            var conpid = "";
            this.CompanyId.map((n) => {
                conpid += n + ",";
            });

            this._contractsManageServiceServiceProxy
                .getContractsToExcel(
                    0,
                    conpid,
                    // this.StartTime,
                    this.EndTime,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },

        onDateChange(e) {
            // if (e.length > 0) {
            // 	this.StartTime = e[0];
            // 	this.EndTime = e[1];
            // 	this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
            // }
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        /**
         * 续签
         */
        renewal(record) {
            ModalHelper.create(
                CreateOrEditPersonnelContracts,
                {
                    id: undefined,
                    userId: record.userId,
                },
                {
                    width: "70%",
                }
            ).subscribe((res) => {
                if (res) {
                    this.loading = true;
                    this._contractsManageServiceServiceProxy
                        .updateContractsToComplete(record.id)
                        .finally(() => {
                            this.loading = false;
                        })
                        .then((res) => {
                            this.getData();
                        });
                }
            });
        },
        /**
         * 不在续签
         */
        noRenewal(record) {
            this.spinning = true;
            this._contractsManageServiceServiceProxy
                .updateContractsToComplete(record.id)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    if (res) {
                        this.getData();
                    }
                });
        },
        companyTreeInit() {
            this._CompanyConfigServiceProxy
                .getPaged(undefined, "", 100, 0)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        companyChange(value) {
            this.CompanyId = value;
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        //批量续签
        Renew(record) {
            //单独发起
            if (record.jobNumber != undefined) {
                ///验证单独发起时是否已经发起
                if (record.hT_STATUS != "待续签") {
                    return abp.message.warn("该员工合同已经发起！");
                }
                this.jobNumber.push(record.jobNumber);
            }
            ///批量发起
            else {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn("请至少选择一项进行操作");
                }
                debugger;
                for (let index = 0; index < this.selectedRows.length; index++) {
                    ///验证批量发起时是否已经发起，存在一个就不能发起
                    if (this.selectedRows[index].hT_STATUS != "待续签") {
                        return abp.message.warn(
                            "批量发起合同中存在已经发起过的合同！"
                        );
                    } else {
                        this.jobNumber.push(this.selectedRows[index].jobNumber);
                    }
                }
            }

            ModalHelper.create(
                ConfirmYear,
                {
                    jobNumber: this.jobNumber,
                    EndDate: this.EndTime,
                },
                {
                    width: "300px",
                }
            ).subscribe((res) => {
                this.getData();
                this.jobNumber = [];
            });
        },
    },
};
</script>

<style scoped>
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
