import { RouteConfig } from "vue-router";
import { LayoutBlock } from "@/layout";

const costcenteremprouting: RouteConfig[] = [
  {
    path: "costcenteremp",
    meta: { title: "成本中心" },
    component: LayoutBlock,
    redirect: "/app/costcenteremp/costcenteremp-page",
    children: [
      {
        name: "costcenteremp-page",
        path: "costcenteremp-page",
        component: () => import("./costcenteremp-page/costcenteremp-page.vue"),
        meta: { title: "costcenteremp-page" },
      },
    ],
  },
];
export default costcenteremprouting;